import React from 'react';

type PaginationProps = {
    t: any;
    pages: number;
    currentPage: number;
    firstItemIndex: number;
    lastItemIndex: number;
    totalItems: number;
    onPageChange: (page: number) => void;
};

const Pagination: React.FC<PaginationProps> = ({ t, pages, currentPage, onPageChange, firstItemIndex, lastItemIndex, totalItems }) => {
    const renderPageNumbers = () => {
        const maxPagesToShow = 3;
        const pageNumbers = [];

        if (pages <= maxPagesToShow) {

            for (let i = 1; i <= pages; i++) {
                pageNumbers.push(
                    <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
                        <button className="page-link z-1 mx-2" onClick={() => onPageChange(i)}>
                            {i}
                        </button>
                    </li>
                );
            }
        } else {

            const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
            const endPage = Math.min(pages, startPage + maxPagesToShow - 1);

            if (startPage > 1) {
                pageNumbers.push(
                    <li key={1} className="page-item">
                        <button className="page-link z-1 ms-2" onClick={() => onPageChange(1)}>
                            1
                        </button>
                    </li>
                );
                if (startPage > 2) {
                    pageNumbers.push(
                        <li key={'ellipsis-start'} className="page-item disabled">
                            <span className="page-link z-1 ms-1"><i className='bi bi-three-dots text-dark'></i></span>
                        </li>
                    );
                }
            }

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
                        <button className="page-link z-1 ms-2 me-1" onClick={() => onPageChange(i)}>
                            {i}
                        </button>
                    </li>
                );
            }

            if (endPage < pages) {
                if (endPage < pages - 1) {
                    pageNumbers.push(
                        <li key={'ellipsis-end'} className="page-item disabled">
                            <span className="page-link z-1 ms-0"><i className='bi bi-three-dots text-dark'></i></span>
                        </li>
                    );
                }
                pageNumbers.push(
                    <li key={pages} className="page-item">
                        <button className="page-link z-1 ms-1 me-2" onClick={() => onPageChange(pages)}>
                            {pages}
                        </button>
                    </li>
                );
            }
        }

        return pageNumbers;
    };

    return (
        <>
            <div></div>
            <nav>
                <ul className="pagination mb-0">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link d-flex align-items-center h-100 me-1" onClick={() => onPageChange(currentPage - 1)}>
                            <i className='bi bi-chevron-left page-link-arrow'></i>
                        </button>
                    </li>
                    {renderPageNumbers()}
                    <li className={`page-item ${currentPage === pages ? 'disabled' : ''}`}>
                        <button className="page-link d-flex align-items-center h-100 ms-1" onClick={() => onPageChange(currentPage + 1)}>
                            <i className='bi bi-chevron-right page-link-arrow'></i>
                        </button>
                    </li>
                </ul>
            </nav>
            <div>
                <span className='text-grey fs-7'>{firstItemIndex}-{lastItemIndex} {t("of")} {totalItems}</span>
            </div>
        </>
    );
};

export default Pagination;