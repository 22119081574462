/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetHistoryAppointmentDocument = gql`
    query GetHistoryAppointment($id: Int!) {
  user {
    id
    company {
      branches {
        bookings(id: $id) {
          bookings {
            id
            date
            status {
              id
              name
            }
            bookingHistories {
              id
              changeTime
              user {
                name
                surname
              }
              client {
                id
                name
                surname
                phone
                email
              }
              bookingHistoryData {
                id
                field
                value
              }
              booking {
                id
              }
              exists
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetHistoryAppointmentQuery__
 *
 * To run a query within a React component, call `useGetHistoryAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHistoryAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHistoryAppointmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHistoryAppointmentQuery(baseOptions: Apollo.QueryHookOptions<GetHistoryAppointmentQuery, GetHistoryAppointmentQueryVariables> & ({ variables: GetHistoryAppointmentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHistoryAppointmentQuery, GetHistoryAppointmentQueryVariables>(GetHistoryAppointmentDocument, options);
      }
export function useGetHistoryAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHistoryAppointmentQuery, GetHistoryAppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHistoryAppointmentQuery, GetHistoryAppointmentQueryVariables>(GetHistoryAppointmentDocument, options);
        }
export function useGetHistoryAppointmentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetHistoryAppointmentQuery, GetHistoryAppointmentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetHistoryAppointmentQuery, GetHistoryAppointmentQueryVariables>(GetHistoryAppointmentDocument, options);
        }
export type GetHistoryAppointmentQueryHookResult = ReturnType<typeof useGetHistoryAppointmentQuery>;
export type GetHistoryAppointmentLazyQueryHookResult = ReturnType<typeof useGetHistoryAppointmentLazyQuery>;
export type GetHistoryAppointmentSuspenseQueryHookResult = ReturnType<typeof useGetHistoryAppointmentSuspenseQuery>;
export type GetHistoryAppointmentQueryResult = Apollo.QueryResult<GetHistoryAppointmentQuery, GetHistoryAppointmentQueryVariables>;
export const GetBookingStatusDocument = gql`
    query GetBookingStatus {
  bookingStatus {
    id
    name
    colorCode
  }
}
    `;

/**
 * __useGetBookingStatusQuery__
 *
 * To run a query within a React component, call `useGetBookingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBookingStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetBookingStatusQuery, GetBookingStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingStatusQuery, GetBookingStatusQueryVariables>(GetBookingStatusDocument, options);
      }
export function useGetBookingStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingStatusQuery, GetBookingStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingStatusQuery, GetBookingStatusQueryVariables>(GetBookingStatusDocument, options);
        }
export function useGetBookingStatusSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBookingStatusQuery, GetBookingStatusQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBookingStatusQuery, GetBookingStatusQueryVariables>(GetBookingStatusDocument, options);
        }
export type GetBookingStatusQueryHookResult = ReturnType<typeof useGetBookingStatusQuery>;
export type GetBookingStatusLazyQueryHookResult = ReturnType<typeof useGetBookingStatusLazyQuery>;
export type GetBookingStatusSuspenseQueryHookResult = ReturnType<typeof useGetBookingStatusSuspenseQuery>;
export type GetBookingStatusQueryResult = Apollo.QueryResult<GetBookingStatusQuery, GetBookingStatusQueryVariables>;
export const GetCurrencySignDocument = gql`
    query GetCurrencySign {
  user {
    id
    company {
      id
      name
      currency {
        code
        currency
        currencySign
      }
    }
  }
}
    `;

/**
 * __useGetCurrencySignQuery__
 *
 * To run a query within a React component, call `useGetCurrencySignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrencySignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrencySignQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrencySignQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrencySignQuery, GetCurrencySignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrencySignQuery, GetCurrencySignQueryVariables>(GetCurrencySignDocument, options);
      }
export function useGetCurrencySignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrencySignQuery, GetCurrencySignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrencySignQuery, GetCurrencySignQueryVariables>(GetCurrencySignDocument, options);
        }
export function useGetCurrencySignSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCurrencySignQuery, GetCurrencySignQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrencySignQuery, GetCurrencySignQueryVariables>(GetCurrencySignDocument, options);
        }
export type GetCurrencySignQueryHookResult = ReturnType<typeof useGetCurrencySignQuery>;
export type GetCurrencySignLazyQueryHookResult = ReturnType<typeof useGetCurrencySignLazyQuery>;
export type GetCurrencySignSuspenseQueryHookResult = ReturnType<typeof useGetCurrencySignSuspenseQuery>;
export type GetCurrencySignQueryResult = Apollo.QueryResult<GetCurrencySignQuery, GetCurrencySignQueryVariables>;
export const GetServiceByIdDocument = gql`
    query GetServiceById($id: Int!) {
  user {
    company {
      services(id: $id) {
        services {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetServiceByIdQuery__
 *
 * To run a query within a React component, call `useGetServiceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetServiceByIdQuery(baseOptions: Apollo.QueryHookOptions<GetServiceByIdQuery, GetServiceByIdQueryVariables> & ({ variables: GetServiceByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceByIdQuery, GetServiceByIdQueryVariables>(GetServiceByIdDocument, options);
      }
export function useGetServiceByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceByIdQuery, GetServiceByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceByIdQuery, GetServiceByIdQueryVariables>(GetServiceByIdDocument, options);
        }
export function useGetServiceByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetServiceByIdQuery, GetServiceByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetServiceByIdQuery, GetServiceByIdQueryVariables>(GetServiceByIdDocument, options);
        }
export type GetServiceByIdQueryHookResult = ReturnType<typeof useGetServiceByIdQuery>;
export type GetServiceByIdLazyQueryHookResult = ReturnType<typeof useGetServiceByIdLazyQuery>;
export type GetServiceByIdSuspenseQueryHookResult = ReturnType<typeof useGetServiceByIdSuspenseQuery>;
export type GetServiceByIdQueryResult = Apollo.QueryResult<GetServiceByIdQuery, GetServiceByIdQueryVariables>;
export type GetHistoryAppointmentQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetHistoryAppointmentQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', branches?: Array<{ __typename?: 'BranchProxy', bookings?: { __typename?: 'BookingPaginated', bookings?: Array<{ __typename?: 'BookingMainProxy', id: number, date?: string | null, status?: { __typename?: 'BookingStatusProxy', id: number, name: string } | null, bookingHistories?: Array<{ __typename?: 'BookingHistoryProxy', id: number, changeTime?: string | null, exists: boolean, user?: { __typename?: 'UserProxy', name?: string | null, surname?: string | null } | null, client?: { __typename?: 'ClientProxy', id: number, name?: string | null, surname?: string | null, phone?: string | null, email?: string | null } | null, bookingHistoryData?: Array<{ __typename?: 'BookingHistoryDataProxy', id: number, field?: string | null, value?: string | null }> | null, booking?: { __typename?: 'BookingMainProxy', id: number } | null }> | null }> | null } | null }> | null } | null } | null };

export type GetBookingStatusQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetBookingStatusQuery = { __typename?: 'Query', bookingStatus: Array<{ __typename?: 'BookingStatusProxy', id: number, name: string, colorCode?: string | null }> };

export type GetCurrencySignQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCurrencySignQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, name: string, currency?: { __typename?: 'CurrencyProxy', code?: string | null, currency?: string | null, currencySign?: string | null } | null } | null } | null };

export type GetServiceByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetServiceByIdQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', services?: { __typename?: 'ServicePaginated', services?: Array<{ __typename?: 'ServiceProxy', id: number, name: string }> | null } | null } | null } | null };
