import React from "react";
import { Spinner } from "react-bootstrap";

interface SpinnerLoaderProps {
    variant: string,
    loadingText: string,
    classSpinner?: string
}
const SpinnerLoader: React.FC<SpinnerLoaderProps> = ({variant, loadingText, classSpinner}) => {
    return (
        <>
            <Spinner as="span" variant={variant} animation="border" className={`text-white ${classSpinner}`} role="status" aria-hidden="true"/>
            <span className="visually-hidden">{loadingText}</span>
        </>
    )
}

export default SpinnerLoader;