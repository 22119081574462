import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { router } from './router/Router';
import './i18n';
import { ApolloProvider } from '@apollo/client';
import { apolloClientMenu } from './apollo/apollo-client';
import './scss/styles.scss';
import "bootstrap/js/dist/dropdown";
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/button';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.scss'

const root = document.getElementById('root')

if (!root) {
    throw new Error('root not found')
}

const container = createRoot(root)

container.render(
    <ApolloProvider client={apolloClientMenu}>
            <RouterProvider router={router}/>
    </ApolloProvider>
);
