import React, { FC } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { useQuery, useReactiveVar } from '@apollo/client';
import { MeUserDocument } from '@me-team/host/src/apollo/globalVar/graphql/globalVar.hooks';
import { currentCompanyIdVar } from '@me-team/host/src/apollo/globalVar/state';

interface ProtectedRegistrationRouteProps {
    element: React.ComponentType;
}

const ProtectedRegistrationRoute: FC<ProtectedRegistrationRouteProps> = ({ element: Component, ...rest }) => {
    const { loading, error } = useQuery(MeUserDocument);

    const currentCompanyId = useReactiveVar(currentCompanyIdVar);

    if (loading) return <div>Loading...</div>;

    if (error) {
        console.error('Error fetching user data:', error);
        return <div>Error loading data</div>;
    }

    if (currentCompanyId) {
        return <Navigate to="/" replace />;
    }

    return <Component />;
};

export default ProtectedRegistrationRoute;
