import React from 'react';
import { NavLink } from 'react-router-dom';
import { Accordion } from "react-bootstrap";

type AccordionNavProps = {
    title: string,
    items: Array<{
        label: string,
        to: string,
        href?: string,
    }>,
    icon: string,
    eventKey: string,
    handleCloseNavbar?: () => void
}
const AccordionNav: React.FC<AccordionNavProps> = ({title, items, icon, eventKey, handleCloseNavbar}) => {
    return (
        <Accordion.Item eventKey={eventKey} className="mb-1">
            <Accordion.Header>
                <i className="bi bi-caret-right-fill fs-10 me-2"></i>
                <div className="d-flex align-items-center text-dark">
                    <i className={`bi ${icon} me-2`}></i>
                    <span>{title}</span>
                </div>
            </Accordion.Header>
            <Accordion.Body className="ms-4 px-2 py-0 mt-1">
                {items.map((item, index) => (
                    <div key={index} className="accordion-body-item mb-1 rounded">
                        {item.to ? (
                            <NavLink to={item.to}
                                     end
                                     className={({isActive}) => `accordion-body-item-link text-decoration-none text-dark d-block py-2 px-4 h-100 w-100 rounded-1 ${isActive ? "bg-additional-violet" : ""}`}
                                     onClick={handleCloseNavbar}>
                                {item.label}
                            </NavLink>
                        ) : (
                            <a href={item.href} className="accordion-body-item-link text-decoration-none text-dark d-block py-2 ps-4 h-100 w-100">
                                {item.label}
                            </a>
                        )}
                    </div>
                ))}
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default AccordionNav;
