import * as Yup from 'yup';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
    try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
        return false;
    }
};


export const addEmployeeFormValidationSchema = (t: any) => {

  return Yup.object({
    name: Yup.string().trim(t('Name cannot be empty or contain only spaces')).required(t('Name employee is required')),
    surname: Yup.string().trim(t('Surname cannot be empty or contain only spaces')).required(t('Surname is required')),
    // patronymic: Yup.string(),
    email: Yup.string().email(t('Invalid email')).required(t('Email is required')),
    phone: Yup.string()
            .required(t('Phone number is required'))
            .test('is-valid-phone', t('Phone number is not valid'), value => isPhoneValid(value || '')),
    passportData: Yup.string().max(10, t('Must be at most 10 characters')).nullable(),
    aboutMe: Yup.string().max(400, t('Must be at most 400 characters')).nullable(),
    employeePosition: Yup.string().required(t('Position is required')),
   specialization: Yup.string()
    .required(t('Specialization is required'))
    .max(50, t('Specialization must be at most 50 characters')),
    role: Yup.string().required(t('Permission is required')),
  });
};