import {ServiceCategoryNewProxy} from "@me-team/host/graphql/types";
import React from "react";
import {TFunction} from "i18next";
import {delModalCategoryContentType, delModalSubcategoryContentType} from "../interfaces/interfaces";

class ServiceValidation {
    public static deleteCategoryValidator = (currentCategory : ServiceCategoryNewProxy,
                                             setDelModalContent:  React.Dispatch<React.SetStateAction<delModalCategoryContentType>>,
                                             translate :  TFunction<"translation", undefined>) => {

        let subcatHasActiveService : boolean = false

        if (currentCategory.childCategories.length !==0) {
            currentCategory.childCategories.forEach((subcategory : ServiceCategoryNewProxy) => {
                if (subcategory.services.length !== 0) {
                    subcatHasActiveService = true
                }
            })
        }


        if (currentCategory.childCategories.length === 0 && currentCategory.services.length === 0) {
            setDelModalContent({
                title: `${translate('servicesPage.deleteModal.delCategoryTitle')} ${currentCategory.name}`,
                description: translate('servicesPage.deleteModal.delDescription.withoutSubcatAndServices'),
                withoutSubcatAndServices : true,
                withServiceInSubcat : false,
                withServiceInCategory : false,
                withSubcat : false
            })
        } else if (currentCategory.services.length !==0) {
            setDelModalContent({
                title: `${translate('servicesPage.deleteModal.cantDelCategoryTitle')}`,
                description: translate('servicesPage.deleteModal.delDescription.withServiceInCategory'),
                withoutSubcatAndServices : false,
                withServiceInSubcat : false,
                withServiceInCategory : true,
                withSubcat : false
            })
        } else if (currentCategory.services.length === 0 && currentCategory.childCategories.length !==0 && !subcatHasActiveService) {
            setDelModalContent({
                title: `${translate('servicesPage.deleteModal.delCategoryTitle')} ${currentCategory.name}`,
                description: translate('servicesPage.deleteModal.delDescription.withSubcat'),
                withoutSubcatAndServices : false,
                withServiceInSubcat : false,
                withServiceInCategory : false,
                withSubcat : true
            })
        } else if (currentCategory.services.length === 0 && currentCategory.childCategories.length !==0 && subcatHasActiveService) {
            setDelModalContent({
                title: `${translate('servicesPage.deleteModal.cantDelCategoryTitle')}`,
                description: translate('servicesPage.deleteModal.delDescription.withServiceInSubcat'),
                withoutSubcatAndServices : false,
                withServiceInSubcat : true,
                withServiceInCategory : false,
                withSubcat : false
            })
        }

    }

    public static delSubcategoryValidator = (currentSubcategory : ServiceCategoryNewProxy,
                                             setDelModalContent:  React.Dispatch<React.SetStateAction<delModalSubcategoryContentType>>,
                                             translate :  TFunction<"translation", undefined>) => {
        console.log('asdasd')
        if (currentSubcategory.services.length === 0) {
            setDelModalContent({
                title: `${translate('servicesPage.deleteModal.delCategoryTitle')} ${currentSubcategory.name}`,
                description: translate('servicesPage.deleteModal.delDescription.withoutSubcatAndServices'),
                withServiceInSubcat : false,
            })
        } else {
            setDelModalContent({
                title: `${translate('servicesPage.deleteModal.cantDelCategoryTitle')}`,
                description: translate('servicesPage.deleteModal.delDescription.withServiceInCategory'),
                withServiceInSubcat : true,

            })
        }

    }
}

export default ServiceValidation