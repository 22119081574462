import React, { ReactNode, useState } from 'react';
import {Breadcrumb} from "../index";
import {Row, Col} from 'react-bootstrap'
import {useTranslation} from "react-i18next";

interface TableHeaderProps {
    children?: ReactNode;
    paths?: any;
    title: string;
    buttons?: ReactNode;
    showFilters?: boolean;
    colSize?: number;
    btnColSize?: number;
}

const TableHeader: React.FC<TableHeaderProps> = ({ children, paths, title, buttons, showFilters, colSize = 6, btnColSize= 6 }) => {
    const {t} = useTranslation();

    return (
        <div>
            <Row className='mb-3 align-items-center'>
                <Col lg={colSize}>
                    <h2 className='mb-0'>{title}</h2>
                    {paths && <Breadcrumb paths={paths} t={t}/>}
                </Col>
                {buttons && <Col lg={btnColSize}>{buttons}</Col>}
            </Row>

            {showFilters && (
                <div className="mb-4">
                    {children}
                </div>
            )}
        </div>
    );
};

export default TableHeader;
