import React from 'react';

interface VioletBadgeProps {
    text?: string;
    children?: React.ReactNode;
}

const VioletBadge: React.FC<VioletBadgeProps> = ({ text, children }) => {
    return (
        <div className="d-flex align-items-center gap-2  rounded bg-violet-light text-violet-hover mb-4" style={{ padding: '12px' }}>
            <i className="bi bi-info-circle"></i>
            <p className="fs-7 mb-0">
                {text ? text : children}
            </p>
        </div>
    );
};

export default VioletBadge;
