import {CompanyProxy, EmployeeInput, EmployeeProxy} from "../graphql/types";

enum UserRole {
    OWNER = 1,
    ADMINISTRATOR = 3,
    EMPLOYEE = 5

}
type PartialEmployeeProxy = Omit<EmployeeProxy, 'workSchedules' | 'userBranchRelations'>;

export default class AccessService {
    get isPremium(): boolean {
        return this._isPremium;
    }
    get currentUserRole(): UserRole | undefined {
        return this._currentUserRole;
    }
    static instance: AccessService;
    private _isPremium: boolean
    private _currentUserRole: UserRole | undefined;
    
    constructor() {

    }

    static getInstance(): AccessService {
        if (!AccessService.instance) {
            AccessService.instance = new AccessService();
        }
        return AccessService.instance;
    }

    public init(paidFeatures: CompanyProxy['paidFeatures']): void {
        if (!paidFeatures) return null;

        const currentDate = new Date();
        const activeSubscription = paidFeatures.find(subscription => {
            const subscriptionEndDate = new Date(subscription.dateOfEnd);
            return subscriptionEndDate > currentDate;
        });

        this._isPremium = activeSubscription?.name === 'Premium';
    }

    public setUserRole(userRole: number): void {
        if (Object.values(UserRole).includes(userRole)) {
            this._currentUserRole = userRole as UserRole;
        }
    }

    canAddEmployee(employees: PartialEmployeeProxy[], employeeInput: EmployeeInput): boolean {
        // TODO: Если премиум возвращать тру
        if (this.isPremium) return true
        
        let employeeCounter = 0;
        let administratorCounter = 0
        let ownerCounter = 0
        employees.forEach(employee => {
            if (!employee.tariffBlocking) {
                const employeeRoleId = employee.role.id;
                if (employeeRoleId === UserRole.OWNER) {
                    ownerCounter++
                } else if (employeeRoleId === UserRole.ADMINISTRATOR) {
                    administratorCounter++
                } else if (employeeRoleId === UserRole.EMPLOYEE) {
                    employeeCounter++
                }
            }
        });
        
        if (employeeInput.role === UserRole.OWNER && ownerCounter >= 1) {
            return false;
        } else if (employeeInput.role === UserRole.ADMINISTRATOR && administratorCounter >= 1) {
            return false;
        } else if (employeeInput.role === UserRole.EMPLOYEE && employeeCounter >= 5) {
            return false;
        }

        return true;
    }
}

