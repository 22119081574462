import { format } from 'date-fns';

interface Item {
    client?: {
        name?: string;
        surname?: string;
        email?: string;
        phone?: string;
    };
    user?: {
        name?: string;
        surname?: string;
    };
}

export const formatDate = (dateString : string) => {
    const date = new Date(dateString);
    return format(date, 'eee, dd MMMM yyyy');
};

export const getName = (i: Item): string | boolean => {
    return i?.client ? `${i.client?.name || ''} ${i.client?.surname || ''} ${i.client?.email || ''} ${i.client?.phone || ''}`.trim().replace(/\s+/g, ' ') :
           i?.user ? `${i.user?.name || ''} ${i.user?.surname || ''}`.trim().replace(/\s+/g, ' ') :
           true;
}
