import React from 'react';
import { useUpdateEmployeeRecordSettingsMutation } from '@me-team/host/src/modules/employeeList/graphql/employeeList.hooks';
import { Modal, Form as Formb, Button, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import EmployeeCell from '@services/ui-components/src/EmployeeCell/EmployeeCell';

interface Employee {
    id: number;
    name: string;
    surname: string;
    position: {
        id: number;
        name: string;
    };
    recordSetting: {
        id: number;
        isDefaultEmployee: boolean;
        isOnlineSignUpAvailable: boolean;
        isReviewsAvailable: boolean;
    };
}

const BookingSettingsModal: React.FC<{ show: boolean; toggleModal: () => void; selectedEmployee: Employee; refetch: () => void }> = ({ show, toggleModal, selectedEmployee, refetch }) => {
    const { t } = useTranslation();
    const initialRecordSetting = selectedEmployee?.recordSetting;

    const [recordSettings] = useUpdateEmployeeRecordSettingsMutation();

    const handleSave = async (values) => {
        try {
            const response = await recordSettings({
                variables: {
                    id: selectedEmployee.recordSetting.id,
                    isOnlineSignUpAvailable: values.isOnlineSignUpAvailable,
                    isDefaultEmployee: values.isDefaultEmployee,
                    isReviewsAvailable: values.isReviewsAvailable,

                }
            });
            refetch();
            toggleModal();
        } catch (error) {
            console.error('Error updating settings:', error);
        }
    };

    return (
        <Modal show={show} onHide={toggleModal} centered>
            <Modal.Header closeButton className='border-0'>
                <h5 className="m-0">{t('Booking Settings')}</h5>
            </Modal.Header>
            <Formik
                initialValues={{
                    isOnlineSignUpAvailable: initialRecordSetting?.isOnlineSignUpAvailable,
                    isDefaultEmployee: initialRecordSetting?.isDefaultEmployee,
                    isReviewsAvailable: initialRecordSetting?.isReviewsAvailable
                }}
                onSubmit={handleSave}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <Modal.Body >

                            <div className="d-flex align-content-center mb-3 border-bottom pb-4">
                                <EmployeeCell i={selectedEmployee} />

                            </div>

                            <Formb.Check
                                type="switch"
                                id="isOnlineSignUpAvailable"
                                label={t('Online Booking')}
                                checked={values.isOnlineSignUpAvailable}
                                onChange={() => setFieldValue('isOnlineSignUpAvailable', !values.isOnlineSignUpAvailable)}
                            />
                            <p className='text-grey fs-7'>{t('Disallow \\ Allow displaying employee and their schedule available for online booking form.')}</p>
                            <Formb.Check
                                type="switch"
                                id="isDefaultEmployee"
                                label={t('Choice Pass')}
                                checked={values.isDefaultEmployee}
                                onChange={() => setFieldValue('isDefaultEmployee', !values.isDefaultEmployee)}
                            />
                            <p className='text-grey fs-7'>{t('Disallow \\ Allow online booking to this employee if client has chosen the option “Employee is not important”.')}</p>
                            <Formb.Check
                                type="switch"
                                id="isReviewsAvailable"
                                label={t('Rating and Reviews')}
                                checked={values.isReviewsAvailable}
                                onChange={() => setFieldValue('isReviewsAvailable', !values.isReviewsAvailable)}
                            />
                            <p className='text-grey fs-7 mb-0'>{t('Hide Show employees rating and reviews.')}</p>
                        </Modal.Body>
                        <Modal.Footer className='border-0'>
                            <Col>
                                <Button variant="outline-primary" type="button" className="btn btn-outline-primary border-grey w-100 h-100 fw-normal " onClick={toggleModal}>
                                    {t('cancel')}
                                </Button>
                            </Col>
                            <Col>
                                <Button type="submit" className="btn btn-primary w-100 text-truncate ">
                                    {t('save')}
                                </Button>
                            </Col>
                            {/* <div className="d-flex justify-content-between mt-3">

                                    </div> */}
                        </Modal.Footer>

                    </Form>
                )}
            </Formik>
        </Modal >
    );
};

export default BookingSettingsModal;