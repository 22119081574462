import React from 'react';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { useTranslation } from "react-i18next";

const WithDocumentTitle = (Component: React.FC, title: string) => {

    return (props: any) => {
        const { t } = useTranslation()
        useDocumentTitle(t(title));
        return <Component {...props} />;
    };
};

export default WithDocumentTitle;