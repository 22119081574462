import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Col, Row, Button, Container, Form } from "react-bootstrap";
import { TableHeader } from '@services/ui-components';
import { useGetEmployeesPositionsQuery, useGetStatusQuery, } from "@me-team/host/src/modules/employeeList/graphql/employeeList.hooks";
import VioletBadge from '@services/ui-components/src/VioletBadge/VioletBadge';
import EmployeesTable from './EmployeesTable/EmployeesTable';
import CustomReactSelect from '@services/ui-components/src/select/CustomReactSelect';
import AccessService from '@me-team/host/services/AccessService';


const EmployeesList: React.FC = () => {
    const { t } = useTranslation();
    const { position } = useParams();

    const accessService = AccessService.getInstance().isPremium
    const role = AccessService.getInstance().currentUserRole;

    const selectPositionRef = useRef<{ reset: () => void }>(null);
    const selectStatusRef = useRef<{ reset: () => void }>(null);

    const handleResetClick = (ref: React.RefObject<{ reset: () => void }>) => {
        if (ref.current) {
            ref.current.reset();
        }
    };
    const inputNameRef = useRef<HTMLInputElement>(null);
    const [showFilters, setShowFilters] = useState(false)
    const [filter, setFilter] = useState({ name: '', positionId: position || '', statusId: '' })
    const [currentFilter, setCurrentFilter] = useState({ positionId: '', statusId: '' })
    const [currentPage, setCurrentPage] = useState(1);
    const { data: poss } = useGetEmployeesPositionsQuery()
    const { data: statuses } = useGetStatusQuery()

    const positions = poss?.user?.company?.employeePositions?.employeePositions
    const status = statuses?.employeeStatus
    const toggleFilters = () => setShowFilters(!showFilters);

    const handlePositionChange = (name: string, id: string) => {
        setCurrentFilter(prevFilter => ({
            ...prevFilter,
            positionId: id
        }));
    };

    const handleStatusChange = (name: string, id: string) => {
        setCurrentFilter(prevFilter => ({
            ...prevFilter,
            statusId: id
        }));
    };
    const applyFilters = () => {
        setFilter({ name: inputNameRef.current.value, ...currentFilter })
        setCurrentPage(1)

    };
    const clearFilters = () => {
        handleResetClick(selectPositionRef);
        handleResetClick(selectStatusRef);
        setCurrentFilter({ positionId: '', statusId: '' });
        setFilter({ name: '', positionId: '', statusId: '' });
        if (inputNameRef.current) {
            inputNameRef.current.value = '';
        }
    };

    return (
        <>
            <VioletBadge>
                {t('On this page are all the employees of your company. To see more information, use the hints in the table header. To start work - configure the work schedule of employees ')}
                <i className="bi  bi-calendar4-week"></i>
                {t('and choose for each service they perform')}
                <i className="bi bi-bookmark"></i>
                {t('Enable online recording if you need it')}
                <i className="bi bi-gear"></i> .
            </VioletBadge>

            <TableHeader
                title={t('List of employees')}
                paths={[{ path: "/employees-list", label: t('Personal') }, { path: "/employees-list", label: t('List of employees') }]}
                buttons={

                    < Container >

                        <Row>
                            <Col xs={4}>

                            </Col>
                            <Col xs={5} >
                                {
                                    role === 5 ||
                                    <Link to='add-employee' className="btn btn-primary fw-bold w-100 text-truncate">
                                        <i className='bi bi-plus-lg me-2'></i>{t('Add Employee')}
                                    </Link>
                                }
                            </Col>
                            <Col xs={3} className='pe-0'>
                                <Button variant="outline-primary" className='w-100  fw-normal' onClick={toggleFilters}>
                                    <i className="bi bi-funnel me-2"></i>  {t('Filters')}
                                </Button>

                            </Col>
                        </Row >
                    </ Container>


                }
                showFilters={showFilters}
            >
                <Row >
                    <Col xs={10}>
                        <Row>
                            <Col>
                                <div className="position-relative">
                                    <Form>
                                        <Form.Control type="text" name="name" className="form-control"
                                            placeholder={t('Search by Name, Surname')}
                                            // onChange={handleNameChange}
                                            ref={inputNameRef}
                                        />
                                    </Form>

                                    <div className='position-absolute top-50 end-0 translate-middle-y '>
                                        <i className='bi bi-search position-absolute top-50 end-0 translate-middle-y text-dark me-3'></i>

                                    </div>
                                </div>

                            </Col>
                            <Col>
                                <CustomReactSelect
                                    ref={selectPositionRef}
                                    optionsArray={positions}
                                    placeholder={t('Select Position')}
                                    setField={handlePositionChange}
                                    fieldName={t('Select Position')}
                                    value="id"
                                />
                            </Col>
                            <Col>
                                <CustomReactSelect
                                    ref={selectStatusRef}
                                    optionsArray={status}
                                    placeholder={t('Status')}
                                    setField={handleStatusChange}
                                    fieldName={t('Status')}
                                    value="id"
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={2} className="d-flex pl-0" style={{ gap: '16px' }}>
                        <Col >
                            <Button
                                variant="outline-dark" className=" border-grey fw-normal btn-block w-100 h-100" onClick={clearFilters}

                            >
                                {t('clear')}
                            </Button>
                        </Col>
                        <Col >
                            <Button variant="primary" className="fw-normal btn-block w-100 btn-block h-100 " onClick={applyFilters}

                            >
                                {t('Apply')}
                            </Button>
                        </Col>

                    </Col>
                </Row>
            </TableHeader >
            <EmployeesTable filter={filter} setCurrentPage={setCurrentPage} currentPage={currentPage} accessService={accessService} position={position} />
        </>
    );
};

export default EmployeesList;