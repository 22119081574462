import React, { Dispatch, SetStateAction, useState } from 'react';
import DropItem from '@services/ui-components/src/DropItem/DropItem';

interface DropPhotosMultiplyProps {
    photosArray: (File | null)[];
    setPhotos: Dispatch<SetStateAction<(File | null)[]>>;
    disabled?: boolean;
    setConfiramitoinField?: () => void;
}

const DropPhotosMultiply: React.FC<DropPhotosMultiplyProps> = ({ photosArray, setPhotos, disabled, setConfiramitoinField }) => {
    const [error, setError] = useState<boolean | string>(false);

    const addPhotoToQualification = (index: number, newPhoto: File | null): void => {
        setPhotos((prevPhotos: (File | null)[]) => {
            const updatedPhotos = [...prevPhotos];
            updatedPhotos[index] = newPhoto;
            if (updatedPhotos.length < 5 && !updatedPhotos.includes(null)) {
                updatedPhotos.push(null);
            }
            return updatedPhotos;
        });

        if (setConfiramitoinField) setConfiramitoinField();
        setError(false);
    };

    const rejection = (errorMsg: string) => {
        setError(errorMsg);
    };

    const renderDropItems = () => {
        const items = photosArray.map((photo, index) => (
            <div style={{ maxWidth: '116px' }} className="p-0 mb-2" key={index + 'photo'}>
                <DropItem
                    disabled={disabled}
                    index={index}
                    previewPhoto={photo ? URL.createObjectURL(photo) : null}
                    handleImage={addPhotoToQualification}
                    rejection={rejection}
                />
            </div>
        ));

        if (photosArray.length < 5 && photosArray[photosArray.length - 1] !== null) {
            items.push(
                <div style={{ maxWidth: '116px' }} className="p-0 mb-2" key={photosArray.length + 'add-photo'}>
                    <DropItem
                        disabled={disabled}
                        index={photosArray.length}
                        previewPhoto={null}
                        handleImage={addPhotoToQualification}
                        rejection={rejection}
                    />
                </div>
            );
        }

        return items;
    };

    return (
        <>
            {renderDropItems()}
            {error && <p className="fs-7 m-0 p-1 text-danger">{error}</p>}
        </>
    );
};

export default DropPhotosMultiply;
