import React, {useEffect, useState} from "react";
import {TableHeader} from "@services/ui-components";
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {SearchComponent} from "./SearchComponent/SearchComponent";
import Button from "react-bootstrap/Button";
import {ServiceCategoriesList} from "./ServiceComponent/ServiceCategoriesList/ServiceCategoriesList";
import {CreateEditModalComponent, RequestInputService} from "@me-pos/service-modal";
import {ServiceCategoryNewProxy} from "@me-team/host/graphql/types";
import i18next from "i18next";
import {ErrorModalComponent} from "@me-pos/error-modal";
import {useGetRoleQuery} from "@me-team/host/src/modules/services/graphql/services.hooks";
import AccessService from "@me-team/host/services/AccessService";

export const ServiceCategories: React.FC = () => {
    const {t: translate} = useTranslation();
    const [showFilters, setShowFilters] = useState(false);
    const [isCreateCategoryModalOpen, setIsCreateCategoryModalOpen] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>(null);
    const [categoryList, setCategoryList] = useState<ServiceCategoryNewProxy[]>([]);
    const [reRender, setRerender] = useState<boolean>(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
    const [hasAccess, setHasAccess] = useState<boolean>(null);
    const role = AccessService.getInstance().currentUserRole;

    useEffect(() => {
        if (role) {
            const OWNER_ROLE: number = 1;
            const ADMIN_ROLE: number = 3;

            if (role === ADMIN_ROLE || role === OWNER_ROLE) {
                setHasAccess(true);
            } else {
                setHasAccess(false);
            }
        }
    }, [role]);

    const getCategoryList = (categoryList: ServiceCategoryNewProxy[]) => {
        setCategoryList(categoryList)
    }

    const HandleToggleFilters = () => {
        setShowFilters(!showFilters)
    }

    const searchHandling = (currentValue: string) => {
        setSearchValue(currentValue ? currentValue : null)
    }


    const onSubmitHandlerRestoreCategory = () => {
        setRerender(!reRender)
        setIsCreateCategoryModalOpen(false)
    }

    return (
        <>
            <TableHeader
                title={translate('Service Categories')}
                paths={[{
                    path: "/service-categories",
                    label: translate('servicesPage.serviceCategoryTitle')
                }]}
                colSize={5}
                btnColSize={7}
                buttons={
                    <Row className='gx-3 justify-content-lg-end'>
                        {hasAccess && <Col lg={4}>
                            <Button className="btn btn-primary w-100" onClick={() => {
                                setIsCreateCategoryModalOpen(true)
                            }}>
                                <i className='bi bi-plus-lg me-2'></i> {translate('servicesPage.addCategory')}
                            </Button>
                        </Col>}
                        <Col lg={2}>
                            <Button className="btn btn-additional-violet fw-normal border-primary text-primary w-100"
                                    onClick={HandleToggleFilters}><i
                                className="bi bi-funnel me-2"></i>{translate('filters')}
                            </Button>
                        </Col>
                    </Row>
                }
                showFilters={showFilters}
            >
                <SearchComponent searchHandling={searchHandling}/>
            </TableHeader>
            <ServiceCategoriesList
                getCategoryList={getCategoryList}
                reRender={reRender}
                searchValue={searchValue}
                setIsErrorModalOpen={setIsErrorModalOpen}
                hasAccess={hasAccess}
            />
            {isCreateCategoryModalOpen &&
                <CreateEditModalComponent
                    i18n={i18next}
                    categoryId={null}
                    subcategoryId={null}
                    isOpen={isCreateCategoryModalOpen}
                    categoryInput={RequestInputService.createServiceCategoryInput(null, null, [])}
                    categoryList={categoryList}
                    isCreateAction={isCreateCategoryModalOpen}
                    onClose={() => {
                        setIsCreateCategoryModalOpen(false);
                    }}
                    onSubmit={onSubmitHandlerRestoreCategory}
                    on500Error={(error) => {
                        setIsCreateCategoryModalOpen(false);
                        setIsErrorModalOpen(500);
                    }}
                    on403Error={(error) => {
                        setIsCreateCategoryModalOpen(false);
                        setIsErrorModalOpen(403);
                    }}
                />}
            {isErrorModalOpen &&
                <ErrorModalComponent
                    i18n={i18next}
                    onClose={() => {
                        setIsErrorModalOpen(null)
                    }}
                    isOpen={!!isErrorModalOpen}
                    currentError={isErrorModalOpen}
                />
            }
        </>
    )
}