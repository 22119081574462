import React, { useState, useRef, useEffect, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

type LanguageDropdownProps = {
    isMobile?: boolean;
    handleCloseNavbar?: () => void
}
const DropdownComponent: React.FC<LanguageDropdownProps> = (props) => {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [innerOpen, setInnerOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleOutsideClick = (event: Event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setOpen(false);
                setInnerOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleDropdownToggle = () => {
        setOpen(!open);
        setInnerOpen(false);
    };

    const handleInnerDropdownToggle = (e: MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        setInnerOpen(!innerOpen);
    };

    const changeLanguage = (lng: string, e: React.MouseEvent) => {
        e.preventDefault();
        i18n.changeLanguage(lng);
        localStorage.setItem('i18nextLng', lng);
        window.location.reload();
        props.handleCloseNavbar();
    };

    const handleExit = () => {
        window.location.href = `${process.env.REACT_APP_POS_URL}/logout`
    };


    return (
        <div ref={dropdownRef} className={`${props.isMobile ? "dropstart" : "dropend"} ${open ? 'show' : ''}`} onClick={handleDropdownToggle}>
            <button type="button" className="border-0 bg-white pe-0" data-bs-toggle="dropdown" aria-expanded={open}>
                <i className={`bi bi-three-dots-vertical fs-16 ${open ? "text-primary" : "text-dark"}`}></i>
            </button>
            <ul className={`dropdown-menu border rounded-1 ${open ? 'show' : ''}`}>
                <li className="dropdown">
                    <a className="dropdown-item mb-1" href="#" role="button" id="languagesDropdown"
                        data-bs-toggle="dropdown" aria-expanded={innerOpen} onClick={handleInnerDropdownToggle}>
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <i className='bi bi-globe me-2'></i>
                                <span>{i18n.language.toUpperCase()}</span>
                            </div>
                            <i className='bi bi-chevron-down'></i>
                        </div>
                    </a>
                    <ul className={`dropdown-menu border rounded-1 ${innerOpen ? 'show' : ''} ${props.isMobile && "dropdown-menu-mobile"}`} aria-labelledby="languagesDropdown">
                        <li><a className="dropdown-item" href="#" onClick={(event => changeLanguage('en', event))}>{t('EN')}</a></li>
                        <li><a className="dropdown-item" href="#" onClick={(event) => changeLanguage('uk', event)}>{t('UK')}</a></li>
                    </ul>
                </li>
                <li><a className="dropdown-item" href="#" onClick={handleExit}><i className="bi bi-box-arrow-left me-2"></i>{t('Exit')}</a></li>
            </ul>
        </div>
    );
};

export default DropdownComponent;
