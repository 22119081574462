import React from 'react';
import AsyncSelect from "react-select/async";
import { useFormikContext } from "formik";
import customStyles from "../ReactSelect/customStyles";

interface Option {
    value: string | number;
    label: string;
}

interface ReactAsyncSelectProps {
    id?: string
    value: Option | null,
    options: Array<any>,
    placeholder: string,
    onChange: (option: Option | null) => void,
    isSearchable: boolean
    name?: string,
    disabled?: boolean
    key?: string,
    isLoading?: boolean,
    loadOptions?: any,
}

const filterOption = (option: Option, inputValue: string) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
};

const ReactAsyncSelect: React.FC<ReactAsyncSelectProps> = ({id, value, name, options,loadOptions, placeholder, onChange, isSearchable, disabled = false, key, isLoading}) => {
    const formikContext = useFormikContext<any>();
    const errors = formikContext?.errors && formikContext.errors[name];
    const touched = formikContext?.touched && formikContext.touched[name];
    return (
        <div>
            <AsyncSelect
                id={id}
                key={key}
                name={name}
                value={value}
                defaultOptions={options}
                loadOptions={loadOptions}
                placeholder={placeholder}
                onChange={onChange}
                isSearchable={isSearchable}
                styles={customStyles(errors, touched)}
                isDisabled={disabled}
                filterOption={filterOption}
                isLoading={isLoading}
            />
        </div>
    );
};

export default ReactAsyncSelect;
