import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb } from '@services/ui-components';
import { useTranslation } from 'react-i18next';
import {Col} from "react-bootstrap";
import MenuBranch from "../../images/menu-branch.svg";
import DishesBranch from "../../images/dishes-branch.svg";
import EmployeeBranch from "../../images/employee-branch.svg";
import TariffBranch from "../../images/tariff-branch.svg";

const sections = [
    { title: 'Меню', description: 'Створення категорій, генерація QR меню', navigateTo: '/menu', showWatchAll: true, showCreate: true, image: <MenuBranch/> },
    { title: 'Страви', description: 'Список страв, додавання страв та інше', navigateTo: '/dishes', showWatchAll: true, showCreate: true, image: <DishesBranch/> },
    { title: 'Персонал', description: 'Список співробітників, посади та інше', navigateTo: '/staff', showWatchAll: true, showCreate: false, image: <EmployeeBranch/>  },
    { title: 'Тарифи', description: 'Тарифні плани, модулі та інше', navigateTo: '/pricing', showWatchAll: true, showCreate: false, image: <TariffBranch/>  },
];

const MainMenu = () => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const params = useParams();

    const handleNavigate = (path: string) => {
        navigate(path);
    };

    return (
        <div className="container mt-4">
            <Breadcrumb paths={[
                {path: "/branches", label: t("branches")},
                {path: `/branch/menu/${params.id}`, label: t("home")},
            ]} t={t}/>
            <div className="row justify-content mt-5">
                {sections.map((section, index) => (
                    <Col key={index} md={6} xxl={4} className="mb-4">
                        <div className="card d-flex flex-column justify-content-between h-100 border rounded-3">
                            <div className="card-body p-4">
                                <h5 className="card-title fs-4">{section.title}</h5>
                                <p className="card-text text-grey mb-0">{section.description}</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                {section.image}
                            </div>
                            <div className="card-footer bg-white border-top-0 p-4 d-flex gap-2 rounded-3">
                                {section.showWatchAll && (
                                    <button onClick={() => handleNavigate(section.navigateTo)} className="btn btn-outline-primary w-100 fw-normal">
                                        Переглянути Всі
                                    </button>
                                )}
                                {section.showCreate && (
                                    <button onClick={() => handleNavigate(`${section.navigateTo}/create`)} className="btn btn-primary w-100">
                                        Створити
                                    </button>
                                )}
                            </div>
                        </div>
                    </Col>
                ))}
            </div>
        </div>
    );
};

export default MainMenu;
