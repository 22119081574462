import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
import { StylesConfig } from 'react-select';

interface Option {
    id?: number;
    value?: string;
    label?: string;
    name?: string;
}

interface CustomReactSelectProps {
    optionsArray: Option[] | undefined;
    placeholder: string;
    setField: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    fieldName: string;
    value: string;
    isTooltip?: boolean;
}

const customStyles = (isTooltip: boolean): StylesConfig<Option, false> => ({
    control: (provided, { isFocused, isSelected }) => ({
        ...provided,
        borderColor: isFocused ? '#ACA1DF' : '#DEDEDE',
        padding: "6px",
        borderRadius: '4px',
        minHeight: '100%',
        boxShadow: isFocused ? '0 0 0 0.2rem rgba(111, 66, 193, 0.25)' : isSelected ? '0 0 0 0.2rem rgba(111, 66, 193, 0.25)' : 'none',
        '&:hover': {
            borderColor: '#ACA1DF',
        },
    }),

    option: (provided, { isSelected }) => ({
        ...provided,
        borderRadius: "4px",
        marginBottom: "8px",
        padding: "4px 12px",
        cursor: "pointer",
        backgroundColor: isSelected ? '#5842BE' : '#FFFFFF',
        color: isSelected ? '#FFFFFF' : '#1F3354',
        '&:hover': {
            backgroundColor: '#EEECF9',
            color: '#1F3354',
        },
    }),

    placeholder: (provided) => ({
        ...provided,
        fontSize: "14px",
        color: "#9D9D9D",
    }),

    input: (provided) => ({
        ...provided,
        fontSize: "14px",
        color: "#1F3354",
    }),

    dropdownIndicator: (provided, { selectProps }) => ({
        ...provided,
        transform: selectProps.menuIsOpen ? 'rotate(180deg)' : null,
        transition: 'transform 0.2s ease',
        marginRight: isTooltip ? '26.26px' : undefined,
    }),

    menu: (provided) => ({
        ...provided,
        fontSize: "14px",
        marginTop: "4px",
    }),

    menuList: (provided) => ({
        ...provided,
        padding: '8px 12px',
    }),

    indicatorSeparator: () => ({
        display: 'none',
    }),

    singleValue: (provided) => ({
        ...provided,
        fontSize: "14px",
    })
});

const CustomCreatableReactSelect: React.FC<CustomReactSelectProps> = ({ optionsArray, placeholder, setField, fieldName, value, isTooltip, initialValue }) => {
    const { t } = useTranslation();
    const [selectedOptionState, setSelectedOption] = useState<Option | null>(null || initialValue);
    const handleChange = (selectedOption: Option | null) => {
        setSelectedOption(selectedOption)
        if (selectedOption) {
            const selectedValue = value === "id" ? selectedOption?.id : selectedOption.value;
            setField(fieldName, selectedValue);
        } else {
            setField(fieldName, null);
        }
    };

    const transformedOptions = optionsArray?.map(i => ({
        id: i?.id,
        value: i.name,
        label: i.name,
        name: i.name
    }));
    const formatCreateLabel = (inputValue: string) => `${t("create")} ${inputValue}`;
    return (
        <CreatableSelect
            placeholder={placeholder}
            styles={customStyles(isTooltip ?? false)}
            options={transformedOptions}
            onChange={handleChange}
            value={selectedOptionState}
            formatCreateLabel={formatCreateLabel}
            noOptionsMessage={() => t("No options")}
        />
    );
};

export default CustomCreatableReactSelect;
