/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const CreateCompanyBeautySalonDocument = gql`
    mutation CreateCompanyBeautySalon($input: CompanyInput!) {
  createCompany(input: $input) {
    category {
      id
      name
    }
    name
    country {
      id
      name
    }
  }
}
    `;
export type CreateCompanyBeautySalonMutationFn = Apollo.MutationFunction<CreateCompanyBeautySalonMutation, CreateCompanyBeautySalonMutationVariables>;

/**
 * __useCreateCompanyBeautySalonMutation__
 *
 * To run a mutation, you first call `useCreateCompanyBeautySalonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyBeautySalonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyBeautySalonMutation, { data, loading, error }] = useCreateCompanyBeautySalonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyBeautySalonMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyBeautySalonMutation, CreateCompanyBeautySalonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyBeautySalonMutation, CreateCompanyBeautySalonMutationVariables>(CreateCompanyBeautySalonDocument, options);
      }
export type CreateCompanyBeautySalonMutationHookResult = ReturnType<typeof useCreateCompanyBeautySalonMutation>;
export type CreateCompanyBeautySalonMutationResult = Apollo.MutationResult<CreateCompanyBeautySalonMutation>;
export type CreateCompanyBeautySalonMutationOptions = Apollo.BaseMutationOptions<CreateCompanyBeautySalonMutation, CreateCompanyBeautySalonMutationVariables>;
export const GetGmtDocument = gql`
    query GetGMT {
  relations {
    timezones {
      id
      name
    }
  }
}
    `;

/**
 * __useGetGmtQuery__
 *
 * To run a query within a React component, call `useGetGmtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGmtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGmtQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGmtQuery(baseOptions?: Apollo.QueryHookOptions<GetGmtQuery, GetGmtQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGmtQuery, GetGmtQueryVariables>(GetGmtDocument, options);
      }
export function useGetGmtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGmtQuery, GetGmtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGmtQuery, GetGmtQueryVariables>(GetGmtDocument, options);
        }
export function useGetGmtSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGmtQuery, GetGmtQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGmtQuery, GetGmtQueryVariables>(GetGmtDocument, options);
        }
export type GetGmtQueryHookResult = ReturnType<typeof useGetGmtQuery>;
export type GetGmtLazyQueryHookResult = ReturnType<typeof useGetGmtLazyQuery>;
export type GetGmtSuspenseQueryHookResult = ReturnType<typeof useGetGmtSuspenseQuery>;
export type GetGmtQueryResult = Apollo.QueryResult<GetGmtQuery, GetGmtQueryVariables>;
export const GetLanguagesDocument = gql`
    query GetLanguages {
  relations {
    languages {
      id
      name
    }
  }
}
    `;

/**
 * __useGetLanguagesQuery__
 *
 * To run a query within a React component, call `useGetLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, options);
      }
export function useGetLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, options);
        }
export function useGetLanguagesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, options);
        }
export type GetLanguagesQueryHookResult = ReturnType<typeof useGetLanguagesQuery>;
export type GetLanguagesLazyQueryHookResult = ReturnType<typeof useGetLanguagesLazyQuery>;
export type GetLanguagesSuspenseQueryHookResult = ReturnType<typeof useGetLanguagesSuspenseQuery>;
export type GetLanguagesQueryResult = Apollo.QueryResult<GetLanguagesQuery, GetLanguagesQueryVariables>;
export const GetCurrenciesDocument = gql`
    query GetCurrencies {
  relations {
    currencies {
      id
      currency
      code
    }
  }
}
    `;

/**
 * __useGetCurrenciesQuery__
 *
 * To run a query within a React component, call `useGetCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrenciesQuery, GetCurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrenciesQuery, GetCurrenciesQueryVariables>(GetCurrenciesDocument, options);
      }
export function useGetCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrenciesQuery, GetCurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrenciesQuery, GetCurrenciesQueryVariables>(GetCurrenciesDocument, options);
        }
export function useGetCurrenciesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCurrenciesQuery, GetCurrenciesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrenciesQuery, GetCurrenciesQueryVariables>(GetCurrenciesDocument, options);
        }
export type GetCurrenciesQueryHookResult = ReturnType<typeof useGetCurrenciesQuery>;
export type GetCurrenciesLazyQueryHookResult = ReturnType<typeof useGetCurrenciesLazyQuery>;
export type GetCurrenciesSuspenseQueryHookResult = ReturnType<typeof useGetCurrenciesSuspenseQuery>;
export type GetCurrenciesQueryResult = Apollo.QueryResult<GetCurrenciesQuery, GetCurrenciesQueryVariables>;
export const GetCompanyDocument = gql`
    query GetCompany {
  user {
    company {
      id
      name
      country {
        id
        name
      }
      city {
        id
        name
      }
      address
      phone
      website
      timeZone {
        id
        name
      }
      languages {
        id
        name
      }
      currency {
        id
        currency
      }
      messageLanguage {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
      }
export function useGetCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
        }
export function useGetCompanySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
        }
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanySuspenseQueryHookResult = ReturnType<typeof useGetCompanySuspenseQuery>;
export type GetCompanyQueryResult = Apollo.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;
export const GetCompanyModulesDocument = gql`
    query GetCompanyModules {
  user {
    company {
      modules {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetCompanyModulesQuery__
 *
 * To run a query within a React component, call `useGetCompanyModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyModulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyModulesQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyModulesQuery, GetCompanyModulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyModulesQuery, GetCompanyModulesQueryVariables>(GetCompanyModulesDocument, options);
      }
export function useGetCompanyModulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyModulesQuery, GetCompanyModulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyModulesQuery, GetCompanyModulesQueryVariables>(GetCompanyModulesDocument, options);
        }
export function useGetCompanyModulesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCompanyModulesQuery, GetCompanyModulesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanyModulesQuery, GetCompanyModulesQueryVariables>(GetCompanyModulesDocument, options);
        }
export type GetCompanyModulesQueryHookResult = ReturnType<typeof useGetCompanyModulesQuery>;
export type GetCompanyModulesLazyQueryHookResult = ReturnType<typeof useGetCompanyModulesLazyQuery>;
export type GetCompanyModulesSuspenseQueryHookResult = ReturnType<typeof useGetCompanyModulesSuspenseQuery>;
export type GetCompanyModulesQueryResult = Apollo.QueryResult<GetCompanyModulesQuery, GetCompanyModulesQueryVariables>;
export type CreateCompanyBeautySalonMutationVariables = Types.Exact<{
  input: Types.CompanyInput;
}>;


export type CreateCompanyBeautySalonMutation = { __typename?: 'Mutation', createCompany: { __typename?: 'CompanyProxy', name: string, category?: { __typename?: 'CompanyTypeProxy', id: number, name: string } | null, country?: { __typename?: 'CountryProxy', id: number, name: string } | null } };

export type GetGmtQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetGmtQuery = { __typename?: 'Query', relations: { __typename?: 'RelationProxy', timezones?: Array<{ __typename?: 'TimeZoneProxy', id: number, name: string }> | null } };

export type GetLanguagesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLanguagesQuery = { __typename?: 'Query', relations: { __typename?: 'RelationProxy', languages?: Array<{ __typename?: 'LanguageProxy', id: number, name: string }> | null } };

export type GetCurrenciesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCurrenciesQuery = { __typename?: 'Query', relations: { __typename?: 'RelationProxy', currencies?: Array<{ __typename?: 'CurrencyProxy', id: number, currency?: string | null, code?: string | null }> | null } };

export type GetCompanyQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCompanyQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', id: number, name: string, address: string, phone: string, website?: string | null, country?: { __typename?: 'CountryProxy', id: number, name: string } | null, city?: { __typename?: 'CityProxy', id: number, name: string } | null, timeZone?: { __typename?: 'TimeZoneProxy', id: number, name: string } | null, languages?: Array<{ __typename?: 'LanguageProxy', id: number, name: string }> | null, currency?: { __typename?: 'CurrencyProxy', id: number, currency?: string | null } | null, messageLanguage?: { __typename?: 'LanguageProxy', id: number, name: string } | null } | null } | null };

export type GetCompanyModulesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCompanyModulesQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', modules?: Array<{ __typename?: 'ModuleProxy', id: number, name?: string | null }> | null } | null } | null };
