import React from 'react';

interface IStep {
    key: string;
    label: string;
}

interface IProgressBar {
    currentStep: number;
    steps: IStep[];
}

const ProgressBar = ({ currentStep, steps }: IProgressBar) => {
    return (
        <div className="py-2 mb-3">
            <ul className="d-flex align-items-center justify-content-center list-unstyled">
                {steps.map((step, index) => (
                    <>
                        <li className="text-grey fw-normal" key={step.key}>
                            <i className={`bi me-2 ${
                                currentStep > index 
                                    ? `bi-${index + 1}-circle-fill text-primary`
                                    : `bi-${index + 1}-circle`} ${
                                currentStep === index ? `bi-${index + 1}-circle text-primary` : ""}`}
                            ></i>
                            <p className={`d-none d-lg-inline-block text-uppercase fs-7 mb-0 ${currentStep >= index  && "text-primary fw-bold"}`}>
                                {step.label}
                            </p>
                        </li>
                        {index < steps.length - 1 && (
                            <i className={`bi bi-arrow-right mx-4 ${currentStep >= index ? "text-primary" : "text-grey"}`}></i>
                        )}
                    </>
                ))}
            </ul>
        </div>
    );
};

export default ProgressBar;
