import React from 'react';
import { useTranslation } from "react-i18next";
import { Modal, Button, Col } from 'react-bootstrap';

type ModalDeleteProps = {
    show: boolean;
    handleClose: () => void;
    handleDelete: () => void;
}

const BranchConfirmDelete: React.FC<ModalDeleteProps> = ({ show, handleClose, handleDelete }) => {
    const { t } = useTranslation();

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
        >
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className="h5 text-dark">{t("Deleting branch")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='text-center'>
                <Col sm={10} className="mx-auto">
                    <p className="mb-0">{t("Do you confirm the deletion of the establishment and all information about it?")}</p>
                </Col>
            </Modal.Body>
            <Modal.Footer className="border-0">
                <Col>
                    <Button
                        variant="outline-primary"
                        className="btn btn-outline-primary border-grey w-100 h-100 fw-normal"
                        onClick={handleClose}
                    >
                        {t('Cancel')}
                    </Button>
                </Col>
                <Col>
                    <Button
                        className="btn btn-primary w-100 text-truncate"
                        type="submit"
                        onClick={handleDelete}
                    >
                        {t('Delete')}
                    </Button>
                </Col>
            </Modal.Footer>
        </Modal>
    );
};

export default BranchConfirmDelete;
