import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {  TabKey } from './types';
import GeneralInfoForm from './tabs/GeneralInfoForm/GeneralInfoForm';
import BeautySalonForm from './tabs/BeautySalonForm/BeautySalonForm';
import RestaurantForm from './tabs/RestaurantForm/RestaurantForm';
import {Col, Row, Container} from "react-bootstrap";
import {CheckBoxBusinessType} from "@services/ui-components";
import TypeGeneral from '../../images/general-type.svg'
import TypeRestaurant from '../../images/restaurant-type.svg'
import TypeSalon from '../../images/salon-type.svg'

const CreateBranch = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState<TabKey>('beauty');

    const ActiveForm = useMemo(() => {
        switch (activeTab) {
            case 'general':
                return <GeneralInfoForm />;
            case 'beauty':
                return <BeautySalonForm />;
            case 'restaurant':
                return <RestaurantForm />;
            default:
                return <GeneralInfoForm />;
        }
    }, [activeTab]);

    return (
        <Container fluid className="px-0 px-lg-6">
            <h2 className='mb-4'>{t("Branch")}</h2>
            {/*<p className='fs-4'>Business Type</p>*/}
            <Row>
                <Col lg={12}>
                    <Row className='mb-4 gap-3 gap-lg-0'>
                        {/*<Col lg={4}>*/}
                        {/*    <CheckBoxBusinessType*/}
                        {/*        id="general"*/}
                        {/*        label={t("general")}*/}
                        {/*        checked={activeTab === 'general'}*/}
                        {/*        onChange={() => setActiveTab('general')}*/}
                        {/*        description='Універсальні модулі для бізнесу'*/}
                        {/*        image={ <TypeGeneral/> }*/}
                        {/*        activeTab={activeTab}*/}
                        {/*    />*/}
                        {/*</Col>*/}
                        {/*<Col lg={4}>*/}
                        {/*    <CheckBoxBusinessType*/}
                        {/*        id="beauty"*/}
                        {/*        label={t("beautySalon")}*/}
                        {/*        checked={activeTab === 'beauty'}*/}
                        {/*        onChange={() => setActiveTab('beauty')}*/}
                        {/*        description='Персонал, створення послуг та інше'*/}
                        {/*        image={ <TypeSalon/> }*/}
                        {/*        activeTab={activeTab}*/}
                        {/*    />*/}
                        {/*</Col>*/}
                        {/*<Col lg={4}>*/}
                        {/*    <CheckBoxBusinessType*/}
                        {/*        id="restaurant"*/}
                        {/*        label={t("restaurant")}*/}
                        {/*        checked={activeTab === 'restaurant'}*/}
                        {/*        onChange={() => setActiveTab('restaurant')}*/}
                        {/*        description='Онлайн меню, створення страв'*/}
                        {/*        image={ <TypeRestaurant/> }*/}
                        {/*        activeTab={activeTab}*/}
                        {/*    />*/}
                        {/*</Col>*/}
                    </Row>

                    <BeautySalonForm/>
                </Col>
            </Row>
        </Container>
    );
};

export default CreateBranch;
