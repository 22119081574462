import React from 'react';
import { useTranslation } from "react-i18next";
import { Modal, Button, Col } from 'react-bootstrap';

type ModalDeleteProps = {
    show: boolean;
    handleClose: () => void;
}

const BranchModalDelete: React.FC<ModalDeleteProps> = ({ show, handleClose }) => {
    const {t} = useTranslation();

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered>
            <Modal.Header
                closeButton
                className='border-0'>
                <Modal.Title className="h5 text-danger text-capitalize">{t("Branch deletion is not possible")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='text-center'>
                <Col sm={10} className="mx-auto">
                    <p className="mb-0">{t("This is the only branch in the company. Before deleting it, create another one.")}</p>
                </Col>
            </Modal.Body>
            <Modal.Footer className='border-0'>
                <Button
                    variant="danger"
                    className="w-100 py-6 px-4 m-0"
                    onClick={handleClose}
                >
                    {t("servicesPage.deleteModal.okBtn")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default BranchModalDelete;
