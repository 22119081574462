import React from 'react';

interface StartRatingProps {
    rating?: number;
    maxStars?: number;
    textColor?: string;
    fontSize?: number;
}

const StarRating: React.FC<StartRatingProps> = ({ rating, maxStars = 5, textColor = "orange", fontSize = 16 }) => {
    const filledStars = rating;

    const renderStars = () => {
        const stars = [];
        for (let i = 0; i < maxStars; i++) {
            if (i < filledStars) {
                stars.push(<i key={i} className={`bi bi-star-fill text-${textColor} me-1 fs-${fontSize}`}></i>);
            } else {
                stars.push(<i key={i} className={`bi bi-star text-${textColor} me-1 fs-${fontSize}`}></i>);
            }
        }
        return stars;
    };

    return (
        <div className='d-flex align-items-center'>
            {renderStars()}
            <span className={`text-${textColor} ms-1`}>{rating ? rating : 0}</span>
        </div>
    );
};

export default StarRating;
