export enum BookingStatus {
    ApproveClient = 1,
    WaitingForClient,
    ClientArrived,
    ClientNotArrived,
    BookingCanceled,
    Completed1,
    Completed2,
}

const bookingStatusDetails = {
    [BookingStatus.ApproveClient]: { name: 'Approve client', color: '#FFD600' },
    [BookingStatus.WaitingForClient]: { name: 'Waiting for client', color: '#EA89C2' },
    [BookingStatus.ClientArrived]: { name: 'Client arrived', color: '#73D2FB' },
    [BookingStatus.ClientNotArrived]: { name: 'Client has not arrived', color: '#5842BE' },
    [BookingStatus.BookingCanceled]: { name: 'Booking was canceled', color: '#DC3545' },
    [BookingStatus.Completed1]: { name: 'Completed', color: '#1AB394' },
    [BookingStatus.Completed2]: { name: 'Completed', color: '#1AB394' },
};

class BookingStatusService {
    private previousStatus: BookingStatus | null = null;
    private previousColor: string | null = null; 

    getStatusDetails(statusId: BookingStatus ) {
        const statusDetail = bookingStatusDetails[statusId];
        const currentStatusName = statusDetail ? statusDetail.name : 'Unknown';
        const currentStatusColor = statusDetail ? statusDetail.color : '#000';

        let statusText = `<span style="color: ${currentStatusColor}">${currentStatusName}</span>`;
        if (this.previousStatus && this.previousStatus in bookingStatusDetails) {
            const previousStatusName = bookingStatusDetails[this.previousStatus].name;
            statusText = `<span style="color: ${this.previousColor}">${previousStatusName}</span> → ${statusText}`;
        }

        this.previousStatus = statusId;
        this.previousColor = currentStatusColor;

        return statusText;
    }
}

const bookingStatusService = new BookingStatusService();
export default bookingStatusService;

