import React from 'react';
import { Link } from "react-router-dom";
import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";
import Logo from '../../images/logo.svg';

type SidebarHeaderProps = {
    isMobile?: boolean
}
const SidebarHeader: React.FC<SidebarHeaderProps> = ({isMobile = false}) => {
    const basePath = process.env.REACT_APP_POS_URL;

    return (
        <div className={`d-flex justify-content-between ${isMobile ? 'py-1 w-100' : 'mb-4 pe-3'}`}>
            <Link to={`${basePath}`} className={`${isMobile ? "d-flex justify-content-center w-100" : "mb-2"}`}>
                <Logo width="105px" height="29px" />
            </Link>
            <LanguageDropdown isMobile={isMobile} />
        </div>
    );
}

export default SidebarHeader;
