import {ApolloError} from "@apollo/client";

class ErrorService {
    public static errorHandling = (error: ApolloError) : number => {
        const errorsCategory = {
            '403' : 'user',
            '500' : 'internal'
        }

        const ERROR_500 : number = 500;
        const ERROR_403 : number = 403;


        let currentError : number
        if (error.graphQLErrors) {
            error.graphQLErrors.forEach((graphQLError) => {
                if (graphQLError.extensions && graphQLError.extensions.code) {
                    const statusCode = graphQLError.extensions.code;
                    if (statusCode === '500') {
                        currentError = ERROR_500
                    }
                    if (statusCode === '403') {
                        currentError = ERROR_403
                    }
                }
                if (graphQLError.extensions) {
                    if (graphQLError.extensions.category === errorsCategory["403"]) {
                        currentError = ERROR_403;
                    }
                    if (graphQLError.extensions.category === errorsCategory["500"]) {
                        currentError = ERROR_500
                    }
                }
            });
        }
        return currentError
    }
}

export default ErrorService