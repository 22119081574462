import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
    useDeleteBranchMutation,
    useGetBranchQuery,
    useGetCitiesQuery,
    useGetCountriesQuery,
    useUpdateBranchBeautySalonMutation
} from '@me-team/host/src/modules/branches/graphql/branches.hooks';
import { useBranchTypes } from '../../hooks/useBranchTypes';
import { SelectOption } from '../CreateBranch/types';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import PhonePicker from '@services/ui-components/src/PhonePicker/PhonePicker';
import ImageCrop from '@services/ui-components/src/ImageCrop/ImageCrop';
import { beautySalonFormValidationSchema } from './constants';
import { ReactSelect, TableHeader, ReactAsyncSelect, Spinner, SpinnerLoader } from '@services/ui-components';
import { useReactiveVar } from '@apollo/client';
import { currentUserRoleVar } from '@me-team/host/src/apollo/globalVar/state';
import DropPhotosMultiply from "../DropPhotosMultiply/DropPhotosMultiply";
import BranchConfirmDelete from '../Branch/BranchConfirmDelete';
import BranchModalDelete from '../Branch/BranchModalDelete';
import {
    branchesLength,
    branchNameState,
    isRefetchBranches
} from "@me-team/host/src/modules/branches/state";
import BranchModalLimit from "../Branch/BranchModalLimit";
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { processImages } from '../DropPhotosMultiply/drop-photos-utils';
import MultipleImageCrop from "../ui-components/MultipleImageCrop/MultipleImageCrop";

interface FormValuesBeautySalon {
    name: string;
    description: string;
    country: number;
    city: number;
    address: string;
    phones: any;
    website: string;
    workSchedule: string;
    image: string;
    logo: File | null;
    customCity?: any;
    isUpdateLogo?: boolean
}

interface FormValuesRestaurant {
    name: string;
    type: number;
    description: string;
    country: number;
    city: number;
    address: string;
    phones: any;
    email: string;
    website: string;
    workSchedule: string;
    image: File | null;
    instagram: string;
    facebook: string;
    youtube: string;
    whatsapp: string;
    customCity?: any;
    isUpdateLogo?: boolean
}

const CurrentBranch: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const basePath = process.env.REACT_APP_POS_URL;
    const navigate = useNavigate();
    const [photos, setPhotos] = useState([]);
    const [initialValues, setInitialValues] = useState<FormValuesBeautySalon | FormValuesRestaurant | null>(null);
    const [countryId, setCountryId] = useState<number | null>(null);
    const userRole = useReactiveVar(currentUserRoleVar);
    const [showCustomCityInput, setShowCustomCityInput] = useState<boolean>(false);
    const [isRestoring, setIsRestoring] = useState<boolean>(false);
    const [branchIdToDelete, setBranchIdToDelete] = useState<number | null>(null);
    const [showModalConfirmDeleteBranch, setShowModalConfirmDeleteBranch] = useState<boolean>(false);
    const [showModalDeleteBranch, setShowModalDeleteBranch] = useState<boolean>(false);
    const [showModalLimit, setShowModalLimit] = useState<boolean>(false);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [isUpdateGallery, setIsUpdateGallery] = useState<boolean>(false);
    const totalActiveBranches = useReactiveVar(branchesLength);
    const { data: branchData, loading, error } = useGetBranchQuery({
        variables: { id: Number(id) },
        onCompleted: async (data) => {
            const images = data?.user?.company?.branchesPaginated?.branches[0]?.branchImages;
            const files = await processImages(images, basePath);
            setPhotos(files);
        },
    });
    const branchName = useReactiveVar(branchNameState);
    useDocumentTitle(`${t("Branch editing")} ${branchName}`);

    const { data: countriesData } = useGetCountriesQuery();
    const { data: citiesData, loading: loadingCities } = useGetCitiesQuery({ variables: { countryId: countryId ?? 0 }, skip: !countryId });
    const { data: branchTypesData } = useBranchTypes();

    const [deleteBranch] = useDeleteBranchMutation()
    const [updateBranch] = useUpdateBranchBeautySalonMutation();

    const countryOptions = countriesData?.relations.countries.map((country) => ({
        label: country.name,
        value: country.id,
    })) || [];

    const cityOptions = citiesData?.relations.cities.map((city) => ({
        label: city.name,
        value: city.id,
    })) || [];

    cityOptions.push({ label: t('Other'), value: -1 });

    useEffect(() => {
        if (loading || !branchData || !branchData.user?.company?.branchesPaginated?.branches[0]) return;

        const currentBranch = branchData.user.company.branchesPaginated.branches[0];
        setCountryId(+currentBranch.country.id);
        branchNameState(currentBranch.name)

        if (currentBranch.deleted) {
            setIsRestoring(true);
        } else {
            setIsRestoring(false);
        }

        if (currentBranch?.category?.name === 'Barber Shop') {
            const newInitialValues: FormValuesBeautySalon = {
                name: currentBranch.name,
                country: +currentBranch.country.id,
                city: +currentBranch.city.id,
                address: currentBranch.address,
                phones: currentBranch.phones.length > 0 ? currentBranch.phones : [{ phone: '' }],
                website: currentBranch.website,
                workSchedule: currentBranch.workSchedule,
                description: currentBranch.description || "",
                image: currentBranch.image,
                logo: null,
            };
            setInitialValues(newInitialValues);
        } else if (currentBranch?.category?.name === 'Restaurant') {
            const newInitialValues: FormValuesRestaurant = {
                name: currentBranch.name,
                type: 1,
                description: currentBranch.description || "",
                country: +currentBranch.country.id,
                city: +currentBranch.city.id,
                address: currentBranch.address,
                phones: currentBranch.phones.length > 0 ? currentBranch.phones : [{ phone: '' }],
                email: '',
                website: currentBranch.website,
                workSchedule: currentBranch.workSchedule,
                image: null,
                instagram: '',
                facebook: '',
                whatsapp: '',
                youtube: '',
            };
            setInitialValues(newInitialValues);
        }
    }, [branchData, loading]);

    const handleSubmit = async (values: FormValuesBeautySalon | FormValuesRestaurant, { setSubmitting, setFieldError }: {
        setSubmitting: (isSubmitting: boolean) => void,
        setFieldError: (field: string, message: string) => void
    }, isRestoring: boolean = false) => {
        const input = {
            category: "barber-shop",
            type: "barber-shop",
            name: values.name,
            country: +values.country,
            city: showCustomCityInput ? null : +values.city,
            otherCity: values?.customCity ? values?.customCity : null,
            address: values.address,
            phones: values.phones.map((phone: any) => ({ phone: phone.phone })),
            website: values.website,
            workSchedule: values.workSchedule,
            description: values.description ? values.description : null,
            images: photos,
            logo: 'logo' in values ? values.logo : null,
            isUpdateLogo: isUpdate,
            isUpdateGallery: isUpdateGallery
        };
        const validPhotos = photos.filter(photo => photo != null)
        input.images = validPhotos;

        try {
            if (isRestoring) {
                const existingBranches = branchData?.user.company.branchesPaginated.branches || [];
                const duplicateBranch = existingBranches.find(branch => branch.name === values.name && !branch.deleted);

                if (duplicateBranch) {
                    setFieldError('name', t('A branch with this name already exists.'));
                    setSubmitting(false);
                    return;
                }
            }
            await updateBranch({
                variables: {
                    id: +id!,
                    restore: isRestoring,
                    input,
                },
            });
            navigate('/branches');
            isRefetchBranches(true);
            setSubmitting(false);
        } catch (error) {
            if (error?.graphQLErrors?.[0]?.extensions?.category === "branch.already_exists") {
                setFieldError('name', t("Branch with this name already exists!"));
            }
            if (error?.graphQLErrors?.[0]?.extensions?.category === "branch.tariff_error") {
                setShowModalLimit(true);
                isRefetchBranches(true);
            }
            setSubmitting(false);
        }
    };

    const handleOpenConfirmModalBranch = (branchId: number) => {
        setBranchIdToDelete(branchId);
        setShowModalConfirmDeleteBranch(true);
        isRefetchBranches(true);
    };

    const handleCloseConfirmModalBranch = () => {
        setBranchIdToDelete(null);
        setShowModalConfirmDeleteBranch(false);
    };

    const handleCloseModalBranch = () => {
        setShowModalDeleteBranch(false);
    };

    const handleCloseModalLimit = () => {
        setShowModalLimit(false);
    };

    const handleDeleteBranch = async () => {
        if (branchIdToDelete !== null) {
            try {
                await deleteBranch({
                    variables: { id: branchIdToDelete },
                });
                navigate("/branches");
            } catch (error) {
                console.error('Error deleting branch:', error);
                if (error?.graphQLErrors?.[0]?.extensions?.category === "branch.latest") {
                    setShowModalDeleteBranch(true);
                }
            } finally {
                handleCloseConfirmModalBranch();
                isRefetchBranches(true);
            }
        }
    };
    const handlePhoto = () => {
        setIsUpdate(true);
    }
    const setFieldConfirmation = () => {
        setIsUpdateGallery(true)
    }
    if (loading) return <Spinner />;
    if (error) return <div>Error: {error.message}</div>;
    if (!initialValues) return <div>No data found</div>;

    return (
        <div className="px-lg-6">
            <TableHeader
                title={isRestoring ? t("Restoring branch") : t("Branch")}
                buttons={
                    !isRestoring ? (
                        <div className="d-flex justify-content-end mt-3 mt-sm-0">
                            <Col xs={12} sm={5} lg={6} xxl={4}>
                                <Button disabled={totalActiveBranches === 1} variant="outline-orange" className="fw-normal px-3 rounded-1 text-capitalize w-100"
                                    onClick={() => handleOpenConfirmModalBranch(branchData?.user?.company?.branchesPaginated?.branches?.[0]?.id)}
                                >{t("Delete branch")}</Button>
                            </Col>
                        </div>
                    ) : null
                }
            />
            <Formik
                initialValues={initialValues}
                validationSchema={beautySalonFormValidationSchema(t)}
                onSubmit={(values, actions) => handleSubmit(values, actions, isRestoring)}
                enableReinitialize
            >
                {({ setFieldValue, values, isSubmitting, errors, resetForm, touched }) => {
                    const handleCityChange = (selectedOption: SelectOption | null) => {
                        if (selectedOption && selectedOption.value === -1 as any) {
                            setShowCustomCityInput(true);
                            setFieldValue('city', '');
                        } else {
                            setShowCustomCityInput(false);
                            setFieldValue('city', selectedOption ? selectedOption.value : '');
                        }
                    };
                    return (
                        <Form>
                            <Row className="pt-3">
                                <Col lg={9} className="pe-lg-5">
                                    <h5 className="fs-4 text-capitalize mb-4">{t("Basic information")}</h5>
                                    <div className="pe-lg-4">
                                        <div className="mb-4">
                                            <Row className="align-items-center">
                                                <Col xs={3} md={2}>
                                                    <label htmlFor="description" className="fs-7 fw-bold">{t('Branch name')}
                                                        <span className="text-danger ms-1">*</span>
                                                    </label>
                                                </Col>
                                                <Col xs={9} md={10}>
                                                    <div className="position-relative">
                                                        <Field disabled={userRole === 5} name="name" placeholder={t('Branch name')} className={`form-control rounded-1 lh-22 pe-5 ${errors.name && touched.name ? "border-danger" : "border-secondary"}`} />
                                                        <div className="pt-1 position-absolute top-50 translate-middle-y end-0 me-3">
                                                            <OverlayTrigger
                                                                overlay={<Tooltip>{t('This data will be displayed in the online booking form')}</Tooltip>}>
                                                                <i className="bi bi-info-circle"></i>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Col xs={9} md={10} className="ms-auto">
                                                <ErrorMessage name="name">{msg => <p
                                                    className="fs-7 py-1 px-2 text-danger m-0">{msg}</p>}</ErrorMessage>
                                            </Col>
                                        </div>

                                        <Row className="mb-4">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="type" className="fs-7 fw-bold mt-2 pt-1">{t('country')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <ReactSelect
                                                    name="country"
                                                    disabled={userRole === 5}
                                                    options={countryOptions}
                                                    value={countryOptions.find((option: any) => option.value === values.country)}
                                                    onChange={(selectedOption: SelectOption | null) => {
                                                        setFieldValue('country', selectedOption ? selectedOption.value : '');
                                                        setCountryId(selectedOption ? parseInt(selectedOption.value, 10) : null);
                                                        setFieldValue('city', '')
                                                    }}
                                                    placeholder={t('Select Country')}
                                                    isSearchable
                                                />
                                                <ErrorMessage name="country">{msg => <p
                                                    className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                            </Col>
                                        </Row>

                                        <Row className="mb-4">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="type" className="fs-7 fw-bold mt-2 pt-1">{t('city')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <ReactAsyncSelect
                                                    key={`city-${values.country}`}
                                                    name="city"
                                                    disabled={userRole === 5}
                                                    options={cityOptions}
                                                    value={cityOptions.find((option: any) => option.value === values.city)}
                                                    onChange={handleCityChange}
                                                    placeholder={t('Select City')}
                                                    isSearchable
                                                    isLoading={loadingCities}
                                                />
                                                <ErrorMessage name="city">{msg => <p
                                                    className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                            </Col>
                                        </Row>

                                        {showCustomCityInput && (
                                            <Row className="mb-4">
                                                <Col xs={3} md={2}>
                                                    <label htmlFor="customCity" className="fs-7 fw-bold pt-2">{t('Add custom city name')}
                                                        <span className="text-danger ms-1">*</span>
                                                    </label>
                                                </Col>
                                                <Col xs={9} md={10}>
                                                    <Field
                                                        name="customCity"
                                                        placeholder={t('Enter custom city name')}
                                                        className={`form-control ${errors.customCity && touched.customCity ? "border-danger" : "border-secondary"}`}
                                                        onChange={(e: any) => {
                                                            setFieldValue('customCity', e.target.value);
                                                        }}
                                                        maxLength={50}
                                                    />
                                                    <ErrorMessage name="customCity">{msg => <p className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                                </Col>
                                            </Row>
                                        )}

                                        <Row className="mb-4">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="description" className="fs-7 fw-bold mt-2 pt-1">{t('address')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <div className="position-relative">
                                                    <Field disabled={userRole === 5} name="address" placeholder={t('Enter your location')} className={`form-control pe-5 ${errors.address && touched.address ? "border-danger" : "border-secondary"}`} />
                                                    <div className="pt-1 position-absolute top-50 translate-middle-y end-0 me-3">
                                                        <OverlayTrigger
                                                            overlay={<Tooltip>{t('This data will be displayed in the online booking form')}</Tooltip>}>
                                                            <i className="bi bi-info-circle"></i>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="address">{msg => <p
                                                    className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                            </Col>
                                        </Row>

                                        <div className="form-group">
                                            <Row className="mb-2">
                                                <Col xs={3} md={2}>
                                                    <label className="fs-7 fw-bold mt-2 pt-1">{t('Phone')}
                                                        <span className="text-danger ms-1">*</span>
                                                    </label>
                                                </Col>
                                                <Col xs={9} md={10}>
                                                    <FieldArray
                                                        name="phones"
                                                        render={({ remove, push, unshift }) => (
                                                            <>
                                                                {values.phones.map((phone: any, index: number) => {
                                                                    return (
                                                                        <div className="d-flex align-items-center w-100 mb-3" key={index}>
                                                                            <div className="w-100">
                                                                                <PhonePicker
                                                                                    disabled={userRole === 5}
                                                                                    key={`phones.${index}.phone`}
                                                                                    name={`phones.${index}.phone`}
                                                                                    value={phone.phone}
                                                                                />
                                                                                <ErrorMessage name={`phones.${index}.phone`}>{msg => <p
                                                                                    className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                                                            </div>
                                                                            {index !== 0 && (
                                                                                <Button
                                                                                    type="button"
                                                                                    variant="outline-secondary"
                                                                                    className="h-100 ms-3 px-3 py-6 fs-normal rounded-1 align-self-start"
                                                                                    onClick={() => remove(index)}
                                                                                    disabled={userRole === 5 || isSubmitting}
                                                                                >
                                                                                    <i className="bi bi-trash3 text-dark"></i>
                                                                                </Button>
                                                                            )}

                                                                            {index === 0 && (
                                                                                <Button
                                                                                    type="button"
                                                                                    variant="outline-secondary"
                                                                                    className="h-100 ms-3 px-3 py-6 fs-normal rounded-1 align-self-start"
                                                                                    onClick={() => push('')}
                                                                                    disabled={userRole === 5 || isSubmitting}
                                                                                >
                                                                                    <i className="bi bi-plus-lg text-dark"></i>
                                                                                </Button>
                                                                            )}

                                                                        </div>
                                                                    )
                                                                })}
                                                            </>
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>

                                        <Row className="mb-4">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="description" className="fs-7 fw-bold mt-2 pt-1">{t('website')}</label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <Field disabled={userRole === 5} name="website" placeholder={t('Site address')} className="form-control rounded-1 lh-22" />
                                                <ErrorMessage name="website">{msg => <p
                                                    className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                            </Col>
                                        </Row>

                                        <div className="mb-4">
                                            <Row className="align-items-center">
                                                <Col xs={3} md={2}>
                                                    <label htmlFor="description" className="fs-7 fw-bold">{t('Work schedule')}
                                                        <span className="text-danger ms-1">*</span>
                                                    </label>
                                                </Col>
                                                <Col xs={9} md={10}>
                                                    <div className="position-relative">
                                                        <Field disabled={userRole === 5} name="workSchedule" placeholder={t('Work schedule')}
                                                            className={`form-control rounded-1 lh-22 pe-5 ${errors.workSchedule && touched.workSchedule ? "border-danger" : "border-secondary"}`} />
                                                        <div className="pt-1 position-absolute top-50 translate-middle-y end-0 me-3">
                                                            <OverlayTrigger
                                                                overlay={<Tooltip>{t('This data will be displayed in the online booking form')}</Tooltip>}>
                                                                <i className="bi bi-info-circle"></i>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Col xs={9} md={10} className="ms-auto">
                                                <ErrorMessage name="workSchedule">{msg => <p
                                                    className="fs-7 py-1 px-2 text-danger m-0">{msg}</p>}</ErrorMessage>
                                                <div className='mt-1 px-2'>
                                                    <small className="text-grey">
                                                        {t('for example, Mon-Sun : 11:00-22:00')}
                                                    </small>
                                                </div>
                                            </Col>
                                        </div>

                                        <Row className="mb-4">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="description"
                                                    className="fs-7 fw-bold pt-2">{t('Description')}</label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <div className="position-relative">
                                                    <Field disabled={userRole === 5} as="textarea" name="description" placeholder={t('Description branch')}
                                                        maxLength={200} className="form-control pe-5 rounded-1" />
                                                    <div className="pt-1 position-absolute top-0 end-0 mt-1 me-3">
                                                        <OverlayTrigger
                                                            overlay={<Tooltip>{t('This data will be displayed in the online booking form')}</Tooltip>}>
                                                            <i className="bi bi-info-circle"></i>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="description">{msg => <p
                                                    className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                                <div className="fs-7 text-grey text-start mt-1 px-1">
                                                    {`${values.description.length}/200`}
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mb-4">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="description"
                                                    className="fs-7 fw-bold">{t('Photo of the branch')}</label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <div className="mb-2">
                                                    <p className="fs-7 mb-0">
                                                        {t("Add jpeg images of the branch premises, interior, etc.")}
                                                        <OverlayTrigger
                                                            overlay={<Tooltip>{t('This data will be displayed in the online booking form')}</Tooltip>}>
                                                            <i className="bi bi-info-circle ms-1 fs-7 pt-1"></i>
                                                        </OverlayTrigger>
                                                    </p>
                                                </div>
                                                <Row className="gx-0">
                                                    <MultipleImageCrop
                                                        photosArray={photos}
                                                        setPhotos={setPhotos}
                                                        setFieldValue={setFieldValue}
                                                        setConfiramitoinField={() => setFieldConfirmation()}
                                                        disabled={userRole === 5}
                                                    />
                                                </Row>
                                                <div className="mt-2">
                                                    <p className="fs-7 text-grey">{t("max-size")}</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg={3} className="ms-auto ps-lg-0">
                                    <h5 className="fs-4 mb-4">{t("logo")}</h5>
                                    <ImageCrop
                                        previewPhoto={initialValues?.image}
                                        setFieldValue={setFieldValue}
                                        fieldSetter={() => handlePhoto()}
                                        fieldName="logo"
                                        modalTitle={t('Branch logo')}
                                        disabled={userRole === 5}
                                    />
                                </Col>
                            </Row>
                            <div className="d-sm-flex my-4 mt-lg-0">
                                <Button onClick={isRestoring ? () => navigate(-1) : () => resetForm()} disabled={userRole === 5} variant="outline-primary" className="fw-normal mb-3 mb-sm-0 me-3 custom-btn rounded-1">
                                    {t("Cancel")}
                                </Button>
                                <Button type="submit" variant="primary" disabled={userRole === 5 || isSubmitting} className="fw-bold custom-btn rounded-1">
                                    {isSubmitting ? (isRestoring ? <SpinnerLoader variant="light" loadingText={t('Restoring...')} /> : <SpinnerLoader variant="light" loadingText={t("Saving...")} />) : (isRestoring ? t('Restore') : t('Save'))}
                                </Button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
            <BranchModalDelete show={showModalDeleteBranch} handleClose={handleCloseModalBranch} />
            <BranchConfirmDelete show={showModalConfirmDeleteBranch} handleClose={handleCloseConfirmModalBranch} handleDelete={handleDeleteBranch} />
            <BranchModalLimit show={showModalLimit} handleClose={handleCloseModalLimit} isRestore />
        </div>
    );
};

export default CurrentBranch;
