import { createBrowserRouter } from 'react-router-dom';
import React from 'react';
import { Branch, CreateBranch, CurrentBranch, MainMenu, MenuItems, ClientsHistoryVisit, EmployeesList, AddEmployee, EditingEmployee, OwnerCreation, Positions, HistoryChangeAppointment } from '../components';
import { ServiceCategories } from '../components/Services/ServiceCategories';
import CreateCompany from '../components/CreateCompany/CreateCompany';
import Page500 from './errorPages/Page500';
import WithDocumentTitle from './withDocumentTitle';
import ProtectedRegistrationRoute from '../components/CreateCompany/ProtectedRegistrationRoute';

const commonRoutes = [
    {
        path: "branches",
        element: React.createElement(WithDocumentTitle(Branch, 'Branches')),
    },
    {
        path: 'create-branch',
        element: React.createElement(WithDocumentTitle(CreateBranch, 'Branch')),
    },
    {
        path: "branch/:id",
        element: <CurrentBranch/>,
    },
    {
        path: "branch/menu/:id",
        element: React.createElement(WithDocumentTitle(MainMenu, 'menu')),
    },
    {
        path: "branch/menu/items",
        element: React.createElement(WithDocumentTitle(MenuItems, 'List of dishes')),
    },
    {
        path: "clients-history-visit",
        element: React.createElement(WithDocumentTitle(ClientsHistoryVisit, 'History of client visits')),
    },
    {
        path: "service-categories",
        element: React.createElement(WithDocumentTitle(ServiceCategories, 'Service Categories')),
    },
    {
        path: "/registration",
        element:  <ProtectedRegistrationRoute
            element={WithDocumentTitle(CreateCompany, 'Create company')}
        />,
    },
    {
        path: 'employees-list',
        element: React.createElement(WithDocumentTitle(EmployeesList, 'List of employees')),
    },
    {
        path: 'employees-list/:position',
        element: React.createElement(WithDocumentTitle(EmployeesList, 'List of employees')),
    },
    {
        path: 'employees-list/add-employee',
        element: React.createElement(WithDocumentTitle(AddEmployee, 'Add an employee')),
    },
    {
        path: 'employees-list/edit-employee/:id',
        element: React.createElement(WithDocumentTitle(EditingEmployee, 'Edit')),
    },
    {
        path: 'create-owner',
        element: React.createElement(WithDocumentTitle(OwnerCreation, 'Add owner')),
    },
    {
        path: 'positions',
        element: React.createElement(WithDocumentTitle(Positions, 'Positions')),
    },
    {
        path: 'change-history/:id',
        element: React.createElement(WithDocumentTitle(HistoryChangeAppointment, 'History of Changes')),
    },
    {
        path: 'error/500',
        element: React.createElement(WithDocumentTitle(Page500, 'Server error')),
    },
];

export const router = createBrowserRouter(commonRoutes);

export default commonRoutes;
