import React, { useState } from 'react';
import { Col, Button, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import i18next from "i18next";
import { Link } from 'react-router-dom';
import { ErrorModalComponent } from "@me-pos/error-modal";
import { EmployeePositionProxy } from '@me-team/host/graphql/types';
import { Pagination, TableHeader } from '@services/ui-components';
import VioletBadge from '@services/ui-components/src/VioletBadge/VioletBadge';
import { useGetEmployeesPositionsTableQuery, useGetCompanyIdQuery } from '@me-team/host/src/modules/employeeList/graphql/employeeList.hooks';
import CreatePositionModal from '../ui-components/CreatePositionModal/CreatePositionModal';
import EditPositionMoadal from '../ui-components/EditPositionModal/EditPositionMoadal';
import DeletePositionModal from '../ui-components/DeletePositionmodal/DeletePositionModal';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import AccessService from '@me-team/host/services/AccessService';
import ErrorService from "../../services/ErrorService";

const Positions: React.FC = () => {
    const { t } = useTranslation();
    const [showPositionModal, setShowPositionModal] = useState(false);
    const [showEditPositionModal, setEditShowPositionModal] = useState(false);
    const [selecteedPosition, setSelectedPosition] = useState(null);

    const [showDeletePositionModal, setDeletePositionhowPositionModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(null);

    const role = AccessService.getInstance().currentUserRole;

    const { data: poss, loading, refetch } = useGetEmployeesPositionsTableQuery({
        variables: { page: currentPage, items: itemsPerPage },
        onError: (error) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error))
        }, context: {
            errorType: 'global',
        },
    })
    const { data: companyId, loading: loadingCompanyId } = useGetCompanyIdQuery({
        onError: (error) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error))
        }, context: {
            errorType: 'global',
        },
    })

    const pageCount = poss?.user.company.employeePositions.pageCount
    const totalItems = poss?.user.company.employeePositions.totalCount || 0;
    const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
    const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

    const positions = poss?.user.company.employeePositions.employeePositions

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber)
    }

    const handlePositionModal = () => setShowPositionModal(prevState => !prevState)

    const editPosition = (employee: EmployeePositionProxy) => {
        setSelectedPosition(employee);
        setEditShowPositionModal(prevState => !prevState);
    }

    const deletePosition = (employee: any) => {
        if (employee) {
            setSelectedPosition(employee);
        }

        setDeletePositionhowPositionModal(prevState => !prevState);
    }
    if (loading || loadingCompanyId) return <Spinner />


    return (
        <>
            <VioletBadge text={t('On this page, you can create new positions, as well as edit and delete existing positions in your company.')} />
            <TableHeader
                title={t('Positions')}
                paths={[{ path: "/positions", label: t('Personnel') }, { path: "/positions", label: t('Positions') }]}
                buttons={
                    <Col className="text-end">
                        {
                            role === 5 ||
                            <Button variant="primary" className="fs-7 fw-bold btn-primary custom-btn"
                                onClick={handlePositionModal}
                            >
                                <i className="bi bi-plus-lg me-1"></i>
                                {t('Add position')}
                            </Button>
                        }

                    </Col>
                }
                showFilters={false}
            >
            </TableHeader >
            <div className='table-responsive scrollbar'>
                <table className="table">
                    <thead>
                        <tr className='fs-7'>
                            <th className='px-4'>{t('Position')}</th>
                            <th> {t('Employees')} </th>
                            <th>{t('Description')}</th>
                            <th>
                                {t('Specialization')} {' '}
                                <OverlayTrigger
                                    key={"bottom"}
                                    placement={"bottom"}
                                    overlay={
                                        <Popover id="popover-contained" className="text-popover ">
                                            <Popover.Body  >
                                                {t('The data in this column is automatically collected from all employees with a specific job title.')}
                                            </Popover.Body>
                                        </Popover>
                                    }
                                >
                                    <i className="bi bi-info-circle " style={{ padding: '0 1px' }}> </i>
                                </OverlayTrigger>
                            </th>
                            {
                                role === 5 || <th>  </th>
                            }

                        </tr>
                    </thead>
                    <tbody>
                        {positions?.map((i) => (
                            <tr key={i?.id}>
                                <td className='align-middle px-4'>
                                    {i.name}
                                </td>
                                <td className='align-middle'>
                                    <Link to={`/employees-list/${i?.id}`}>{i?.employees.length}</Link>


                                </td>
                                <td className='align-middle' style={{ width: '400px' }}>
                                    <Col className='p-2'>
                                        {i?.description || <small> — </small>}
                                    </Col>

                                </td>
                                <td className='align-middle' style={{ width: '400px' }}>
                                    <Col className='p-2'>
                                        {Array.from(new Set(i?.employees.map(employee => employee.specialization).filter(Boolean))).join("; ") || <small> — </small>}
                                    </Col>

                                </td>
                                {
                                    role === 5 ||
                                    <td className='align-middle text-end'>
                                        <div className="d-flex justify-content-end">
                                            <OverlayTrigger overlay={<Tooltip>{t('Edit')}</Tooltip>}>
                                                <Button
                                                    variant='outline-secondary'
                                                    className="outline-secondary text-dark m-1 p-0"
                                                    onClick={() => editPosition({
                                                        id: i.id,
                                                        name: i.name,
                                                        description: i.description,
                                                    })}
                                                    style={{ height: '39px', width: '39px' }}
                                                >
                                                    <i className="bi bi-pencil"></i>
                                                </Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip>{t('Delete position')}</Tooltip>}>
                                                <Button
                                                    variant='outline-secondary'
                                                    className="outline-secondary text-dark m-1 p-0"
                                                    onClick={() => deletePosition(i)}
                                                    style={{ height: '39px', width: '39px' }}
                                                >
                                                    <i className="bi bi-trash"></i>
                                                </Button>
                                            </OverlayTrigger>
                                        </div>
                                    </td>

                                }

                            </tr>
                        ))}
                    </tbody>
                </table >
            </div >

            <div className="d-flex justify-content-between align-items-center mb-3" >
                <Pagination
                    t={t}
                    pages={Number(pageCount)}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    firstItemIndex={firstItemIndex}
                    lastItemIndex={lastItemIndex}
                    totalItems={totalItems}
                />
            </div >


            <DeletePositionModal
                show={showDeletePositionModal}
                handleModal={deletePosition}
                selectedPosition={selecteedPosition}
                positions={positions}
                refetch={refetch}
            />
            <CreatePositionModal
                show={showPositionModal}
                handleModal={handlePositionModal}
                companyId={companyId?.user.company.id}
                refetch={refetch}
            />
            <EditPositionMoadal
                show={showEditPositionModal}
                handleModal={editPosition}
                companyId={companyId?.user.company.id}
                refetch={refetch}
                selectedPosition={selecteedPosition}
            />
            {isErrorModalOpen &&
                <ErrorModalComponent
                    i18n={i18next}
                    onClose={() => {
                        setIsErrorModalOpen(null)
                    }}
                    isOpen={!!isErrorModalOpen}
                    currentError={isErrorModalOpen}
                />
            }
        </>
    );
};

export default Positions;