import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { Col, Row, Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import CustomReactSelect from '@services/ui-components/src/select/CustomReactSelect';
import { useDismissEmployeeMutation, useDeleteDismissEmployeeMutation, useGetDismissalReasonQuery } from '@me-team/host/src/modules/employeeList/graphql/employeeList.hooks';

interface Employee {
    id: number;
    name: string;
    surname: string;
    position: {
        id: number;
        name: string;
    };
    dismissalEmployee: {
        id: number;
    }
}
interface FormData {
    employeeId: number;
    endOfCooperation: string;
    reasonId: number;
    comment: string;
}

const DeleteEmployeeModal: React.FC<{ show: boolean, onHide: () => void, selectedEmployee: Employee, refetch: () => void }> = ({ show, onHide, selectedEmployee, refetch }) => {
    const { t } = useTranslation();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [formData, setFormData] = useState<FormData>({
        employeeId: +"",
        endOfCooperation: "",
        reasonId: +"",
        comment: ""
    });

    const [reasonId, setReasonId] = useState({ reasonId: +'' })
    const [errorComment, setErrorComment] = useState(false)
    const [startDate, setStartDate] = useState(null);
    const [date] = useState();

    const [deleteEmployee] = useDismissEmployeeMutation()
    const [dismissDeleteEmployee] = useDeleteDismissEmployeeMutation()
    const { data } = useGetDismissalReasonQuery()

    const toggleConfirmDeleting = async () => {

        setShowConfirmationModal(!showConfirmationModal)
    }

    const dismissDeleting = async (event: React.MouseEvent) => {
        event.preventDefault();

        try {
            const response = await dismissDeleteEmployee({
                variables: {
                    id: selectedEmployee?.dismissalEmployee?.id
                }
            })
            refetch()
            onHide()
        } catch (error) {
            console.error('Error dismissDeleting employee:', error);
        }
    }
    const confirmDeleting = async () => {
        if (!formData) {
            console.error('Form data is not defined');
            return;
        }
        try {
            const response = await deleteEmployee({
                variables: {
                    employeeId: formData.employeeId,
                    endOfCooperation: formData.endOfCooperation,
                    reason: formData.reasonId,
                    comment: formData.comment
                }
            })
            refetch()
            toggleConfirmDeleting()
        } catch (error) {
            console.error('Error dismissing employee:', error);
        }
    };


    const handleChange = (date: Date, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        if (date) {
            setStartDate(date);
            const formattedDate = date.toISOString().slice(0, 10);
            setFieldValue('endOfCooperation', formattedDate);
        }
    };


    const handleReasonId = (name: string, id: string) => {
        setReasonId(prevFilter => ({
            ...prevFilter,
            reasonId: +id
        }));
    };
    const validationSchema = Yup.object().shape({
        endOfCooperation: Yup.string()
            .required(t('End date of cooperation is mandatory')),
        reasonId: Yup.string()
            .required(t('Please select a reason')),
    })
    return (
        <>
            <Modal
                size="lg"
                show={show}
                onHide={() => {
                    setStartDate(null);
                    onHide();
                }}
                centered
            >
                <Modal.Header className='border-0' closeButton>
                    <h5 className="m-0">{t('Dismissal of Employee')}</h5>
                </Modal.Header>
                <Formik
                    initialValues={{
                        employeeId: +selectedEmployee?.id,
                        endOfCooperation: "",
                        reasonId: +"",
                        comment: ""
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, actions) => {
                        if (values.reasonId === 4 && values.comment.length < 1) {
                            setErrorComment(true)
                            return
                        }
                        try {
                            setFormData(values)
                            toggleConfirmDeleting();
                            onHide();
                        } catch (error) {
                            console.error('Error dismissing employee:', error);
                        } finally {
                            actions.setSubmitting(false);
                        }
                    }}
                >
                    {({ isSubmitting, setFieldValue, values }) => (
                        <Form>
                            <Modal.Body style={{ padding: '16px 28px 16px 28px' }}>


                                <Row className="py-3">
                                    <Col xs={3} className="my-auto">
                                        <strong>
                                            <span className="fs-7">
                                                {t('Name')}
                                            </span>
                                        </strong>
                                    </Col>
                                    <Col xs={9}>
                                        <span className="fs-7">{selectedEmployee?.name}</span>
                                    </Col>
                                </Row>

                                <Row className="py-3">
                                    <Col xs={3} className="my-auto">
                                        <strong>
                                            <span className="fs-7">
                                                {t('Last name')}
                                            </span>
                                        </strong>
                                    </Col>
                                    <Col xs={9}>
                                        <span className="fs-7">{selectedEmployee?.surname}</span>
                                    </Col>
                                </Row>

                                <Row className="py-3">
                                    <Col xs={3} className="my-auto">
                                        <strong>
                                            <span className="fs-7">
                                                {t('Position')}
                                            </span>
                                        </strong>
                                    </Col>
                                    <Col xs={9}>
                                        <span className="fs-7">{selectedEmployee?.position.name}</span>
                                    </Col>
                                </Row>

                                <Row className="py-2">
                                    <Col xs={3} className="pe-0 pt-2">
                                        <strong className="text-dark fs-7">
                                            {t('End Date of')}
                                        </strong>
                                        <br />
                                        <strong className='fs-7'>
                                            {t('Cooperation')} <span className="text-danger">*</span>
                                        </strong>
                                    </Col>
                                    <Col xs={9} className="text-dark">
                                        <DatePicker
                                            className="form-control fs-7"
                                            wrapperClassName='w-100'
                                            selected={startDate}
                                            placeholderText={t('dd.mm.yyyy')}
                                            onChange={(date) => handleChange(date, setFieldValue)}
                                            dateFormat="dd.MM.yyyy"
                                        />
                                        <ErrorMessage name="endOfCooperation">
                                            {msg => <p className="fs-7 p-1 text-danger m-0">{msg}</p>}
                                        </ErrorMessage>
                                    </Col>
                                </Row>

                                <Row className="py-2">
                                    <Col xs={3} className="pe-0 pt-2">
                                        <strong className="text-dark fs-7">
                                            {t('Reason for Dismissal')}
                                            <span className="text-danger"> *</span>
                                        </strong>
                                    </Col>
                                    <Col xs={9} className="text-dark fs-7">
                                        <CustomReactSelect
                                            optionsArray={data?.dismissalReason}
                                            placeholder={t('Reason for Deletion')}
                                            setField={setFieldValue}
                                            fieldName="reasonId"
                                            value="id"
                                            additionalParam={handleReasonId}
                                        />
                                        <ErrorMessage name="reasonId">
                                            {msg => <p className="fs-7 p-1 text-danger m-0">{msg}</p>}
                                        </ErrorMessage>
                                    </Col>
                                </Row>

                                <Row className="py-2">
                                    <Col xs={3} className="pe-0 pt-2">
                                        <strong className={`${reasonId.reasonId !== 4 && "text-grey"} fs-7`}>
                                            {t('Comment')}
                                            <span className="text-danger"> *</span>
                                        </strong>
                                    </Col>
                                    <Col xs={9} className="text-dark" data-controller="component--datepicker" data-current-locale="en">
                                        <Field
                                            as="textarea"
                                            name="comment"
                                            rows={3}
                                            className="form-control fs-7"
                                            placeholder={t('Explain the reason for dismissal')}
                                            disabled={reasonId.reasonId !== 4}
                                            maxLength={200}
                                        />
                                        <span className={`fs-7 ${values.comment.length > 200 ? "text-danger" : "text-grey"} `}>
                                            {values.comment.length}/200
                                        </span>
                                        {errorComment && reasonId.reasonId === 4 ? (
                                            <p className="fs-7 p-1 text-danger m-0">{t('Explain the reason for dismissal')}</p>
                                        ) : ''}
                                    </Col>
                                </Row>




                            </Modal.Body>
                            <div style={{ padding: ' 12px' }}>
                                <Row className="gx-3  justify-content-end">
                                    {selectedEmployee?.dismissalEmployee?.id && (
                                        <Col>
                                            <Button variant="danger" className="text-truncate" onClick={dismissDeleting}>
                                                {t('Cancel Dismissal')}
                                            </Button>
                                        </Col>
                                    )}
                                    <Col xs={3}>
                                        <Button type="submit" variant="primary" className="w-100 text-truncate" disabled={isSubmitting}>
                                            {t('Confirm')}
                                        </Button>
                                    </Col>
                                    <Col xs={3}>
                                        <Button
                                            variant="outline-dark"
                                            className="w-100 h-100 fw-normal"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setStartDate(null);
                                                onHide();
                                            }}
                                        >
                                            {t('Dismiss')}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>

                        </Form>)}
                </Formik>
            </Modal >

            <Modal
                show={showConfirmationModal}
                onHide={toggleConfirmDeleting}
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('Dismissal of Employee')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {t('Do you really want to dismiss from position?')} {selectedEmployee?.name} {selectedEmployee?.surname}?
                </Modal.Body>
                <Modal.Footer >

                    <Col>
                        <button className="btn btn-outline-primary border-grey w-100 h-100 fw-normal" onClick={toggleConfirmDeleting}>
                            {t('Dismiss')}
                        </button>
                    </Col>
                    <Col>
                        <button className="btn btn-primary w-100 text-truncate" onClick={confirmDeleting}>
                            {t('Confirm')}
                        </button>
                    </Col>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteEmployeeModal;