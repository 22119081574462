import React from 'react';
import { useTranslation } from 'react-i18next';
import {Col, Row} from "react-bootstrap";
import ProgressBar from '@services/ui-components/src/ProgressBar/ProgressBar';
import { InfoBanner } from "@services/ui-components";
import CreateCompanyForm from './tabs/BeautySalonForm/CreateCompanyForm';
import {Container} from "react-bootstrap";

interface IStep {
    key: string;
    label: string;
}

const steps = (t: any): IStep[] => [
    { key: 'registration', label: t('Registration') },
    { key: 'create-company', label: t('Create company') },
    { key: 'create-owner', label: t('Create owner') },
];

const CreateCompany = () => {
    const { t } = useTranslation();
    const stepsArray = steps(t);

    return (
        <Container fluid className="px-0 px-lg-6">
            <ProgressBar currentStep={1} steps={stepsArray}/>
            <InfoBanner>
                <p className="mb-0">{t("Please fill in the information about your company. Required fields are marked with an asterisk - *.")}</p>
            </InfoBanner>
            <h2 className='mb-4'>{t('company')}</h2>
            <Row>
                <Col lg={12}>
                    <CreateCompanyForm />
                </Col>
            </Row>
        </Container>
    );
};

export default CreateCompany;
