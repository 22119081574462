import React, {ReactNode, useEffect} from "react";
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

type SimpleModalProps = {
    isOpen: boolean
    onClose : () => void
    title : string
    description : string
    children : ReactNode
}

export const SimpleModal : React.FC<SimpleModalProps> = (props) => {
    const [show, setShow] = useState(props.isOpen);

    useEffect(() => {
        setShow(props.isOpen);
    }, [props.isOpen]);

    const handleClose = () => {
        setShow(false);
        props.onClose();
    };

    return (
        <>
            <Modal show={show}
                   onHide={handleClose}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered>
                <Modal.Header closeButton className={"border-bottom-0"}>
                    <Modal.Title>
                        <h5 className={"mb-0"}>{props.title}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className={"mb-0 text-center"}>
                        {props.description}
                    </p>
                </Modal.Body>
                <Modal.Footer className={"border-top-0"}>
                    {props.children}
                </Modal.Footer>
            </Modal>
        </>
    );

}