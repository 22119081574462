import { useFormikContext } from 'formik';
import { PhoneInput } from 'react-international-phone';
import React, { useState } from 'react';
import {useTranslation} from 'react-i18next';

interface PhoneInputProps {
    name: string;
    placeholder?: string;
    value?: string;
    key?: string;
    disabled?: boolean;
}

function getValueByPath(obj: Record<string, any>, path: string) {
    const keys = path.split('.');
    return keys.reduce((acc, key) => acc && acc[key], obj);
}

const PhonePicker: React.FC<PhoneInputProps> = ({ name, placeholder, value, key, disabled }) => {
    const {t} = useTranslation();
    const { setFieldValue, errors, touched } = useFormikContext<any>();
    const [phone, setPhone] = useState(value || '');

    const isInvalid = (errors[name] && touched[name]) || (getValueByPath(errors, name) && getValueByPath(touched, name));

    return (
        <>
            <PhoneInput
                key={key}
                name={name}
                className='fs-7 height-100 w-100'
                placeholder={t('Enter phone number')}
                inputClassName={`form-control fs-7 rounded-1 text-truncate ${isInvalid ? "border-danger" : "border-secondary"}`}
                inputStyle={{ height: '50px', paddingLeft: "65px", fontSize: "16px" }}
                countrySelectorStyleProps={{
                    className: 'position-absolute',
                    style: { height: '50px' },
                    buttonClassName: `h-100 rounded-start-1 ps-2 pe-1 ${isInvalid ? "border-danger" : "border-secondary"}`,
                }}
                value={phone}
                onChange={(phone) => {
                    setPhone(phone);
                    setFieldValue(name, phone);
                }}
                disabled={disabled}
            />
        </>
    );
};

export default PhonePicker;
