import React, {SetStateAction, useEffect, useState} from "react";
import {Pagination} from "@services/ui-components";
import {ServiceComponent} from "../ServiceListComponent";
import {useGetServicesQuery} from "@me-team/host/src/modules/services/graphql/services.hooks";
import {ServiceCategoryNewProxy} from "@me-team/host/graphql/types";
import {useTranslation} from "react-i18next";
import ErrorService from "../../../../services/ErrorService";
import {Col, Container, Row} from "react-bootstrap";

type ServiceCategoriesListProps = {
    getCategoryList: (categoryList: ServiceCategoryNewProxy[]) => void
    reRender: boolean
    searchValue: string | null
    setIsErrorModalOpen: React.Dispatch<SetStateAction<number>>
    hasAccess: boolean
}

export const ServiceCategoriesList: React.FC<ServiceCategoriesListProps> = (props) => {

    const {t: translate} = useTranslation();
    const [categoryListPerPage, setCategoryListPerPage] = useState<ServiceCategoryNewProxy[]>(null)
    const [categoryList, setCategoryList] = useState<ServiceCategoryNewProxy[]>(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const [totalItems, setTotalItems] = useState(1);
    const [pageCount, setPageCount] = useState(0);

    const {refetch: refetchCategoryListPerPage} = useGetServicesQuery({
        variables: {
            id: null,
            itemsPerPage: itemsPerPage,
            serviceDeleted: false,
            categoryName: props.searchValue,
            page: currentPage
        },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setCategoryListPerPage(data.serviceCategories.items)
            setPageCount(parseInt(data.serviceCategories.pageCount || '0'))
            setTotalItems(data.serviceCategories.totalCount || 0)
        }, onError: (error) => {
            props.setIsErrorModalOpen(ErrorService.errorHandling(error))
        }
    })

    const {refetch: refetchCategoryList} = useGetServicesQuery({
        variables: {id: null, itemsPerPage: 1000, serviceDeleted: false, categoryName: null, page: 1},
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            props.getCategoryList(data.serviceCategories.items)
            setCategoryList(data.serviceCategories.items)
        }, onError: (error) => {
            props.setIsErrorModalOpen(ErrorService.errorHandling(error))
        }
    })

    const refreshCategoryListPerPage = () => {
        refetchCategoryListPerPage().then((data) => {
            setCategoryListPerPage(data.data.serviceCategories.items)
            setPageCount(parseInt(data.data.serviceCategories.pageCount || '0'))
            setTotalItems(data.data.serviceCategories.totalCount || 0)
        })
        refetchCategoryList().then((data) => {
            setCategoryList(data.data.serviceCategories.items)
        })
    }

    useEffect(() => {
        refreshCategoryListPerPage()
    }, [props.reRender]);


    const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
    const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };
    return <>
        {categoryListPerPage && categoryListPerPage.length !== 0 &&
            <Container>
                <Row className={"gx-3 table-header-background py-3 ps-4"}>
                    <Col xs={3}>
                        {translate('servicesPage.categoryOrSubcategory')}
                    </Col>
                    <Col xs={5}>
                        {translate('description')}
                    </Col>
                    <Col xs={2}>
                        {translate('servicesPage.serviceQty')}
                    </Col>
                </Row>
            </Container>
        }
        {categoryListPerPage && categoryList && (categoryListPerPage.map((serviceItem: ServiceCategoryNewProxy, index: number) => (
            <ServiceComponent key={index} category={serviceItem}
                              categoryList={categoryList}
                              refreshCategoryListPerPage={refreshCategoryListPerPage}
                              setIsErrorModalOpen={props.setIsErrorModalOpen}
                              hasAccess={props.hasAccess}
            />)))}
        {categoryListPerPage && categoryListPerPage.length !== 0 &&
            <div className="d-flex justify-content-between align-items-center my-4">
                <Pagination
                    t={translate}
                    pages={Number(pageCount)}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    firstItemIndex={firstItemIndex}
                    lastItemIndex={lastItemIndex}
                    totalItems={totalItems}
                />
            </div>
        }
        {categoryListPerPage && categoryListPerPage.length === 0 &&
            <p className={"mb-0 mt-5 pt-5 text-center fw-bold fs-3"}>{translate("NoDataFoundForTheGivenValues")}</p>
        }
    </>
}