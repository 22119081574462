import * as Yup from 'yup';

export const restaurantFormValidationSchema = (t: any) => Yup.object({
    name: Yup.string(),
    country: Yup.string(),
    city: Yup.string(),
    address: Yup.string(),
    phone: Yup.string(),
    website: Yup.string().url('Введите корректный URL').nullable(),
    schedule: Yup.string(),
    description: Yup.string(),
    photos: Yup.mixed(),
});
