import moment, {Moment} from "moment";

export const hexToRgbaWithOpacity = (hex: string) => {
    const hexColor = hex;
    let rgbaColor = ``;
    if (hexColor) {
        const r = parseInt(hexColor.slice(1, 3), 16);
        const g = parseInt(hexColor.slice(3, 5), 16);
        const b = parseInt(hexColor.slice(5, 7), 16);
        const alpha = 0.1;
        rgbaColor = `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    return rgbaColor;
};

export const formatDate = (date: string | Date | Moment): string => {
    return moment(date).format('YYYY-MM-DD')
}