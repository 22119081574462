import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { App } from '../components/App/App';
import menuRoutes from '@me-team/menu/src/router/Router';
import commonRoutes from '@me-team/common/src/router/Router';
import Page404 from "@me-team/common/src/router/errorPages/Page404";

const routes = [
    {
        path: '/',
        element: <App/>,
        children: [
            ...menuRoutes,
            ...commonRoutes,
            {
                path: '*',
                element: <Page404 />
            }
        ],

    }
]

export const router = createBrowserRouter(routes);

export default routes
