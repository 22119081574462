import {Breadcrumb} from "@services/ui-components";
import React from "react";
import {useTranslation} from "react-i18next";


export const Home = () => {
    const {t} = useTranslation();
    return(
        <div>
            <h2 className='mb-0'>Company</h2>
            <Breadcrumb
                paths={[
                    {path: "/", label: t("home")},
                ]}
                t={t}/>
        </div>
    )
}