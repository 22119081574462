export interface ImagesArray {
  image?: string;
}

export const urlToFile = async (url: string, filename: string, mimeType: string): Promise<File> => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], filename, { type: mimeType });
};

export const processImages = async (images?: string[] | ImagesArray[], basePath?: string): Promise<(File | null)[]> => {
  if (images?.length) {
    const imagePromises = images.map(async (imageObject, index) => {
      let imageUrl: string | undefined;
      if (typeof imageObject === 'string') {
        imageUrl = imageObject;
      } else {
        imageUrl = imageObject.image;
      }
      if (!imageUrl) {
        return null;
      }
      const fileType = imageUrl.split('.').pop();
      const mimeType = `image/${fileType}`;
      const file = await urlToFile(`${basePath}/${imageUrl}`, `image-${index}.${fileType}`, mimeType);
      return file;
    });

    const files = await Promise.all(imagePromises);
    return files;
  } else {
    return [null];
  }
};
